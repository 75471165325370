import Model from "../model";
import {
  getCandidateTags,
  getCityTags,
  getCandidateTagsByName,
  getCityTagsByName,
  saveAccountCommonTag
} from '../../services/api1'
export default Model.getInstance(
  class extends Model {
    namespace = "global";
    state = {
      collapsed: true,
      pathname: "",
      drawerStack: [],
      clientWidth: document.body.clientWidth,
      selectedTabKey: '',//判断二级菜单是否被切换
      selectedTabKeyStatus: true,//判断二级菜单是否被点击
      compareDomHeightResult: false,//比较两个节点的高度，大于为true,小于false
      tagsListInfo: {records: [], loading: false},//标签数据
      tagsByNameListInfo: {records: [], loading: false},//根据类型和名称查询的标签数据
      selectTags: {
        address: [],//地点
        industry: [],//行业
        function: [],//职能
        ForwardLocation: [], //期望地点
        JobIndustry: [], //候选人行业
        JobFunction: [], //候选人职能
        WorkIndustry: [], //候选人行业
        WorkFunction: [], //候选人职能
        NowLocation: [],//当前地点
      },//选中的标签列表
      difficultyCompetitor: {//职位难度和竞争方
        difficulty: '1',
        competitor: '1'
      },
      difficultyList: [//职位难度列表
        {uid: "244", key: "1", value: "难度小",},
        {uid: "245", key: "2", value: "难度中等",},
        {uid: "246", key: "3", value: "难度大",}
      ],
      competitorList: [
        {uid: "248", key: "1", value: "独家",},
        {uid: "249", key: "2", value: "三家以内竞争",},
        {uid: "250", key: "3", value: "多家竞争",}
      ]
    };

    actions = {
      updateDrawerStack(data) {
        let drawerStack = this.getState().global.drawerStack;
        if (data.type === "add") {
          if (drawerStack.length !== 0) {
            let beforeDrawer = drawerStack[drawerStack.length - 1];
            // 添加时候把栈中前一个drawer隐藏起来
            beforeDrawer.componentProps.stackVisible = false;
          }
          // data.currentState = this.getState()[data.model];
          drawerStack = drawerStack.concat(data);
        }
        if (data.type === "pop") {
          drawerStack = drawerStack.slice(0, drawerStack.length - 1);
          let current = drawerStack[drawerStack.length - 1];
          if (drawerStack.length !== 0) {
            // 出栈时候把栈中前一个drawer显示
            current.componentProps.stackVisible = true;
          }
        }
        this.dispatch({
          type: "global/updateStack",
          payload: drawerStack
        });
      },
      //获取浏览器宽度
      getClientWidth(){
        let clientWidth = document.body && document.body.clientWidth;
        this.dispatch({
          type: "global/setClientWidth",
          payload: {clientWidth}
        });
        window.onresize = () => {
          clientWidth = document.body && document.body.clientWidth;
          this.dispatch({
            type: "global/setClientWidth",
            payload: {clientWidth}
          });
        };
      },
      //浏览器变化，比较元素的高度
      compareDomHeight(a, b, c){
        let state = document.querySelector(`${a} ${b}`) && document.querySelector(`${a} ${b} ${c}`);
        if (a && b && c && state) {
          let bh = document.querySelector(`${a} ${b}`).clientHeight;
          let ch = document.querySelector(`${a} ${b} ${c}`).clientHeight;
          this.dispatch({
            type: "global/onChangeCompareDomHeight",
            payload: {type: bh >= ch}
          });
          window.onresize = () => {
            let status = (document.querySelector(`${a} ${b}`) && document.querySelector(`${a} ${b} ${c}`));
            let ab = document.querySelector(`${a} ${b}`);
            let abc = document.querySelector(`${a} ${b} ${c}`);
            if (status && ab && abc) {
              bh = ab.clientHeight;
              ch = abc.clientHeight;
              this.dispatch({
                type: "global/onChangeCompareDomHeight",
                payload: {type: bh >= ch}
              });
            }
          };
        }
      },
      //根据标签类型查询标签
      async queryTagsList(data){
        let key;
        let delStatus;
        key = data.key;
        delStatus = data.delStatus;
        this.dispatch({
          type: "global/onChangeTagsList",
          payload: {loading: true},//标签数据
        });
        if (key.indexOf('-') !== -1) {
          key = key.split('-')[0];
        }
        let resultData = {};
        if (key === 'address' || key === 'ForwardLocation' || key === 'NowLocation') {
          await getCityTags().then(res => {
            //data标签类型(1-行业,2-职能,3-候选人标签)
            // console.log('resultData', res);
            resultData = res;
            if (res.code === 200) {
              this.dispatch({
                type: "global/onChangeTagsList",
                payload: {records: res.result || [], loading: false},//标签数据
              });
            }
          })
        } else {
          let type = 1;
          switch (key) {
            case 'industry':
              type = 1;
              break;
            case 'function':
              type = 2;
              break;
            case 'Tag':
              type = 3;
              break;
            case 'JobIndustry':
              type = 1;
              break;
            case 'JobFunction':
              type = 2;
              break;
            case 'WorkIndustry':
              type = 1;
              break;
            case 'WorkFunction':
              type = 2;
              break;
            default:
              break;
          }
          var param = {
            type:type,
            delStatus:delStatus
          }
          await getCandidateTags(param).then(res => {
            //data标签类型(1-行业,2-职能,3-候选人标签)
            resultData = res;
            if (res.code === 200) {
              this.dispatch({
                type: "global/onChangeTagsList",
                payload: {records: res.result || [], loading: false},//标签数据
              });
            }
          })
        }
        return resultData;
      },
      //根据标签类型和标签名称查询标签
      searchCandidateTagsByName(data){
        if (data.type.indexOf('-') !== -1) {
          data.type = data.type.split('-')[0];
        }
        this.dispatch({
          type: "global/onChangeCandidateTagsByNameList",
          payload: {loading: true},//标签数据
        });
        if (data.type === 'address' || data.type === 'ForwardLocation' || data.type === 'NowLocation') {
          getCityTagsByName(data).then(res => {
            if (res.code === 200) {
              this.dispatch({
                type: "global/onChangeCandidateTagsByNameList",
                payload: {records: res.result, loading: false},//标签数据
              });
            }
          });
        } else {
          let tagType = 1;
          switch (data.type) {
            case 'industry':
              tagType = 1;
              break;
            case 'function':
              tagType = 2;
              break;
            case 'Tag':
              tagType = 3;
              break;
            case 'JobIndustry':
              tagType = 1;
              break;
            case 'JobFunction':
              tagType = 2;
              break;
            case 'WorkIndustry':
              tagType = 1;
              break;
            case 'WorkFunction':
              tagType = 2;
              break;
            default:
              break;
          }
          let d = {...data, tagType};
          getCandidateTagsByName(d).then(res => {
            if (res.code === 200) {
              this.dispatch({
                type: "global/onChangeCandidateTagsByNameList",
                payload: {records: res.result, loading: false},//标签数据
              });
            }
          });
        }
      },

      // 保存常用标签
      async saveAccountCommonTags(data){
        if (data.key.indexOf('-') !== -1) {
          data.key = data.key.split('-')[0];
        }
        let status = false;
        let type = 1;
        switch (data.key) {
          case 'industry':
            type = 1;
            break;
          case 'function':
            type = 2;
            break;
          case 'Tag':
            type = 3;
            break;
          case 'address':
            type = 4;
            break;
          case 'JobIndustry':
            type = 1;
            break;
          case 'JobFunction':
            type = 2;
            break;
          case 'WorkIndustry':
            type = 1;
            break;
          case 'WorkFunction':
            type = 2;
            break;
          case 'ForwardLocation':
            type = 4;
            break;
          case 'NowLocation':
            type = 4;
            break;
          default:
            break;
        }
        let d = {type, tagIdList: data.tagIdList};
        if (!!data.id) {
          d = {...d, id: data.id};
        }
        await saveAccountCommonTag(d).then(res => {
          if (res.code === 200) {
            status = true;
          }
        });
        return status;
      }
    };

    reducers = {
      updateStack(state, {payload: data}) {
        return {...state, drawerStack: data};
      },

      changeLayoutCollapsed(state, {payload: collapsed}) {
        return {
          ...state,
          collapsed
        };
      },

      setPathname(state, {payload: pathname}) {
        return {
          ...state,
          pathname
        };
      },

      //获取浏览器的可是窗口宽度
      setClientWidth(state, {payload: pathname}) {
        return {
          ...state,
          ...pathname
        };
      },

      //选择的二级菜单key
      onChangeSelectedTabKey(state, {payload: pathname}) {
        return {
          ...state,
          selectedTabKey: pathname.key,
          selectedTabKeyStatus: pathname.status
        };
      },

      //比较两个dom节点的高度
      onChangeCompareDomHeight(state, {payload: pathname}) {
        return {
          ...state,
          compareDomHeightResult: pathname.type
        };
      },

      //改变标签列表
      onChangeTagsList(state, {payload: pathname}) {
        return {
          ...state,
          tagsListInfo: {...state.tagsListInfo, ...pathname}
        };
      },
      //改变根据类型和名称查询的标签列表
      onChangeCandidateTagsByNameList(state, {payload: pathname}) {
        return {
          ...state,
          tagsByNameListInfo: {...state.tagsByNameListInfo, ...pathname}
        };
      },
      //修改选中的地点标签列表
      onChangeSelectTags(state, {payload: pathname}) {
        return {
          ...state,
          selectTags: {...state.selectTags, ...pathname}
        };
      },
      //修改竞争方和职位难度
      onChangeDifficultyCompetitor(state, {payload: pathname}) {
        return {
          ...state,
          difficultyCompetitor: {...state.difficultyCompetitor, ...pathname}
        };
      },
    };
  }
);
