/**
 * Created by CI11840 on 2019/3/1.
 */
import Model from "../model";

import {
  getFlowNodes,
  //保存职位流程
 // savePositionFlow,
  findList,
  positionFlowQuery
  //getChargeNodes
} from '../../services/api3'
export default Model.getInstance(
  class extends Model {
    namespace = "operation";

    state = {
      operationAddProgressModal: {visible: false},
      operationAddReportModal: {visible: false},
      //获取职位流程
      getFlowNodesStore:[],
      //保存职位流程
      //savePositionFlowStore:{},
      //获取流程节点中的收费节点
      //getChargeNodesStore:{},
      findListStore:{
        // current:1,
        // pages:1,
        records:[],
        // size:10,
        // total:4
      },
      //查询职位流程
      positionFlowQueryStore:{},
      //查询职位流程传数据
      newPositionFlowQueryStore:{}
    };

    actions = {
      getFlowNodesAction(){
        getFlowNodes().then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "operation/getFlowNodesReducer",
              payload:res.result
            });
          }
        });
      },
      // savePositionFlowAction(data){
      //   savePositionFlow(data).then(res => {
      //     if (res.code === 200) {
      //       this.dispatch({
      //         type: "operation/savePositionFlowReducer",
      //         payload:res
      //       });
      //     }
      //   });       
      // },
      // getChargeNodesAction(data){
      //   getChargeNodes().then(res => {
      //     if (res.code === 200) {
      //       this.dispatch({
      //         type: "operation/getChargeNodesReducer",
      //         payload:res.result
      //       });
      //     }
      //   });
      // }
      findListAction(data){
        this.dispatch({
          type: "operation/findListReducer",
          payload: {listLoading: true}
        });
        findList(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "operation/findListReducer",
              payload: {...res.result, listLoading: false} 
            });
          }
        });
      },
      //查询职位流程
     async positionFlowQueryAction(data){
       let resultData={};
       await positionFlowQuery(data).then(res => {
        resultData=res;
          // if (res.code === 200) {
          //   this.dispatch({
          //     type: "operation/positionFlowQueryReducer",
          //     payload:res.result
          //   });
          // }
        });
        return resultData;
      }

      // //查询职位流程
      // positionFlowQueryAction(data){
      //   positionFlowQuery(data).then(res => {
      //      if (res.code === 200) {
      //        this.dispatch({
      //          type: "operation/positionFlowQueryReducer",
      //          payload:res.result
      //        });
      //      }
      //    });
      //  }
    };

    reducers = {
      onChangeOperationAddProgressModal(state, {payload: pathname}) {
        return {
          ...state,
          operationAddProgressModal: pathname
        };
      },
      onChangeOperationAddReportModal(state, {payload: pathname}) {
        return {
          ...state,
          operationAddReportModal: pathname
        };
      },
      getFlowNodesReducer(state, {payload: pathname}){
        return {
          ...state,
          getFlowNodesStore: pathname
        };
      },
      // savePositionFlowReducer(state,{payload:pathname}){
      //   return {
      //     ...state,
      //     savePositionFlowStore:pathname
      //   }
      // },
      // getChargeNodesReducer(state,{payload:pathname}){
      //   return {
      //     ...state,
      //     getChargeNodesStore:pathname
      //   }
      // }
      findListReducer(state,{payload:pathname}){
        return {
          ...state,
          findListStore:{...state.findListStore, ...pathname}

        }
      },
      //查询职位流程
      positionFlowQueryReducer(state,{payload:pathname}){
        return {
          ...state,
          positionFlowQueryStore:pathname
        }
      },
      //查询职位流程传数据
      transmissionDataPF(state,{payload:pathname}){
        return {
          ...state,
          newPositionFlowQueryStore:pathname
        }
      },
    };
  }
);
