/**
 * Created by CI11840 on 2019/3/1.
 */
import Model from "../model";
// import { getPositionAdvisers } from '../../services/api1'
import { getCandidateList } from '../../services/api2'

export default Model.getInstance(
  class extends Model {
    namespace = "candidate";

    state = {
        canSearchModel: {visible: false},
        connectModel: {
            isViewDialog: true,
            dialogData: {
                title:'添加联系',
                submitText:'提交',
                submitFun: () => {
                    return this.handleSubmit();
                },
                // closeDialog: () => {
                //     this.setState({
                //         dialogData: dialogData,
                //         isViewDialog:false
                //     });
                // }
            }
        },
        createCanDrawer: {visible: false},
        createCanInfo: {visible: false},
        canDetailDrawer: {visible: false},
        canActivityDrawer: {visible: false},
        compareDrawer: {visible: false},
        compareResume: {visible: false},
        isViewContact: true,
        isViewPosition: false,
        isViewAttention: true,
        candidateListData: {},//候选人列表查询的数据
    };

    actions = {
        //候选人列表查询
        queryCanList(data){
            return getCandidateList(data).then(res => {
                if (res.code === 200) {
                    this.dispatch({
                        type: "candidate/searchCandidateList",
                        payload: res.result
                    });
                }
                return res;
            });
        },
    };

    reducers = {
        openDialogModel(state, {payload: pathname}) {
            return {
                ...state,
                connectModel:{
                    isViewDialog: true,
                    dialogData: {
                        title:'添加联系',
                        submitText:'提交',
                        submitFun: () => {
                            return this.handleSubmit();
                        },
                    }
                }
            };
        },
        closeDialogModel(state, {payload: pathname}) {
            return {
                ...state,
                connectModel:{
                    isViewDialog: false
                }
            };
        },
        showCanSearchModel(state, {payload: pathname}) {
            return {
                ...state,
                canSearchModel: pathname
            };
        },
        showCreateCanDrawer(state, {payload: pathname}) {
            return {
                ...state,
                createCanDrawer: pathname
            };
        },
        showCreateCanInfo(state, {payload: pathname}) {
            return {
                ...state,
                createCanInfo: pathname
            };
        },
        showCanDetailDrawer(state, {payload: pathname}) {
            return {
                ...state,
                canDetailDrawer: pathname
            };
        },
        showCanActivityDrawer(state, {payload: pathname}) {
            return {
                ...state,
                canActivityDrawer: pathname
            };
        },
        showCompareDrawer(state, {payload: pathname}) {
            return {
                ...state,
                compareDrawer: pathname
            };
        },
        showCompareResume(state, {payload: pathname}) {
            return {
                ...state,
                compareResume: pathname
            };
        },

        addRecord(state, {payload: pathname}) {
            return {
                ...state,
                isViewContact: pathname
            };
        },
        joinPosition(state, {payload: pathname}) {
            return {
                ...state,
                isViewPosition: pathname
            };
        },
        attentionList(state, {payload: pathname}) {
            return {
                ...state,
                isViewAttention: pathname
            };
        },
        //候选人列表查询的数据
        searchCandidateList(state, {payload: pathname}) {
            return {
                ...state,
                candidateListData: pathname
            };
        },
    };
  }
);
