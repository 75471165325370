/**
 * Created by CI11840 on 2019/3/25.
 */
import XHR from "services";

let prefix = '/cts/v1.0';

//新建候选人模板数据
export async function getCandidateTempFields(params) {
    return XHR({
        url: prefix + '/common/template/web/query',
        method: 'POST',
        body: params
    });
}

//新建候选人保存
export async function saveCanFields(params) {
    return XHR({
        url: prefix +  '/candidate/web/save',
        method: 'POST',
        body: params
    });
}

//新建候选人保存(职位中)
export async function saveFieldsPos(params, positionUid) {
    return XHR({
        url: prefix +  '/candidate/web/saveToPosition?positionUid='+positionUid,
        method: 'POST',
        body: params
    });
}

//候选人详情模板数据
export async function getCandidateDetail(id, params) {
    return XHR({
        url: prefix +  '/candidate/web/query/'+id+params,
        method: 'GET',
    });
}

//候选人详情更新数据
export async function saveCanDetail(params) {
    return XHR({
        url: prefix +  '/candidate/web/update/',
        method: 'POST',
        body: params
    });
}

//候选人列表
export async function getCandidateList(params) {
    return XHR({
        url: prefix +  '/candidate/web/query/page/es',
        method: 'POST',
        body: params
    });
}

//候选人沟通联系记录
export async function queryCandidateCommunication(params) {
    return XHR({
        url: prefix +  '/position/positionFlow/web/queryCandidateCommunication',
        method: 'POST',
        body: params
    });
}


//查找用户自定义列表(分页)
export async function getAttentPage(params) {
    return XHR({
        url: prefix +  '/candidate/AttentionTable/web/query/Page',
        method: 'POST',
        body: params
    });
}

//查找用户自定义列表(不分页)
export async function getAttentList(params) {
    return XHR({
        url: prefix +  '/candidate/AttentionTable/web/query/list',
        method: 'POST',
        body: params
    });
}

//保存用户自定义列表，加入文件夹，订阅条件列表（传值是查询条件）
export async function joinFileSave(params) {
    return XHR({
        url: prefix +  '/candidate/AttentionTable/web/save',
        method: 'POST',
        body: params
    });
}

//修改用户自定义列表
export async function editFileSave(params) {
    return XHR({
        url: prefix +  '/candidate/AttentionTable/web/update',
        method: 'POST',
        body: params
    });
}

//添加关注候选人(加入关注列表)
export async function saveToAttention(params) {
    return XHR({
        url: prefix +  '/candidate/AttentionTable/web/saveToAttention',
        method: 'POST',
        body: params
    });
}

//删除关注列表
export async function deleteAttention(params) {
    return XHR({
        url: prefix +  '/candidate/AttentionTable/web/remove/'+params,
        method: 'GET',
    });
}

//移除关注候选人
export async function removeAttention(params) {
    return XHR({
        url: prefix +  '/candidate/AttentionTable/web/removeAttention',
        method: 'POST',
        body: params
    });
}

//查询关注候选人列表
export async function getCandAttent(params) {
    return XHR({
        url: prefix +  '/candidate/web/query/attention',
        method: 'POST',
        body: params
    });
}

//上传简历附件解析
export async function parseResume(params) {
    return XHR({
        url: prefix +  '/candidate/web/parseResume',
        method: 'POST',
        body: params
    });
}

//候选人添加新简历
// export async function uploadResume(params) {
//     return XHR({
//         url: prefix +  '/candidate/web/uploadResume',
//         method: 'POST',
//         body: params
//     });
// }

//根据标签、行业、职能类型id查找标签
export async function getCandidateTagInIds(params) {
    return XHR({
        url: prefix +  '/candidate/candidateTag/web/getCandidateTagInIds',
        method: 'POST',
        body: params
    });
}

//根据城市id集合查找标签列表
export async function getCityTagInIds(params) {
    return XHR({
        url: prefix +  '/candidate/cityTag/web/getCityTagInIds',
        method: 'POST',
        body: params
    });
}

//全部职位
export async function getCanAllPos(params) {
    return XHR({
        url: prefix +  '/position/web/getCandidatePositions',
        method: 'POST',
        body: params
    });
}

//进行中的职位
export async function getMygoingPos(params) {
    return XHR({
        url: prefix +  '/position/web/getMyOngoingPosition',
        method: 'POST',
        body: params
    });
}

//加入职位
export async function joinPositionCan(params) {
    return XHR({
        url: prefix +  '/position/web/joinPosition',
        method: 'POST',
        body: params
    });
}

// //我参与的职位
// export async function getInvolvedPos(params) {
//     return XHR({
//         url: prefix +  '/position/web/getInvolvedPosition'+params,
//         method: 'GET',
//     });
// }
//
// //全部职位
// export async function getCanAllPos(params) {
//     return XHR({
//         url: prefix +  '/position/web/getCandidateAllPosition'+params,
//         method: 'GET',
//     });
// }

//候选人锁定/解锁
export async function lockCandidate(params) {
    return XHR({
        url: prefix +  '/candidate/web/lock'+params,
        method: 'GET',
    });
}

//查询相似候选人
export async function queryLike(params) {
    return XHR({
        url: prefix +  '/candidate/web/queryLike'+params,
        method: 'GET',
    });
}

//手机号邮箱相似候选人
export async function queryByPhoneOrMail(params) {
    return XHR({
        url: prefix +  '/candidate/web/queryByPhoneOrMail',
        method: 'GET',
        body: params
    });
}

//查询操作日志
export async function queryLogs(params) {
    return XHR({
        url: prefix +  '/candidate/web/queryLogs?id='+params,
        method: 'GET',
    });
}

//查询操作日志（分页）
export async function queryLogsPage(params) {
    return XHR({
        url: prefix +  '/candidate/web/queryLogsPage',
        method: 'POST',
        body: params
    });
}

//查找下载候选人列表
export async function canDownlist(params) {
    return XHR({
        url: prefix +  '/candidate/candidateDownload/web/query',
        method: 'POST',
        body: params
    });
}

//查询候选人原始简历
export async function queryResumes(params) {
    return XHR({
        url: prefix + '/candidate/web/queryResumes?id='+params,
        method: 'GET',
    });
}

//添加联系
export async function saveCommunication(params, id, languageType) {
    return XHR({
        url: prefix + `/candidate/web/saveCommunication?id=${id}&languageType=${languageType}`,
        method: 'POST',
        body: params
    });
}

//候选人添加新简历
export async function uploadResume(params) {
    return XHR({
        url: prefix + '/candidate/web/uploadResume',
        method: 'POST',
        body: params
    });
}

//查看/下载候选人简历(type:0 查看 1下载)
export async function viewResume(params) {
    return XHR({
        url: prefix + '/candidate/web/viewResume'+params,
        method: 'GET',
    });
}

//保存下载候选人
export async function saveDownResume(params) {
    return XHR({
        url: prefix + '/candidate/candidateDownload/web/save',
        method: 'POST',
        body: params
    });
}

//获取当前登录人账号信息
export async function getLoginAccount() {
    return XHR({
        url: prefix + '/authcenter/account/web/getLoginAccount',
        method: 'GET',
    });
}

//候选人评分
export async function scoring(params) {
    return XHR({
        url: prefix + '/candidate/web/scoring',
        method: 'POST',
        body: params
    });
}

// 上传头像
export async function uploadAvatar(params) {
    return XHR({
        url: prefix + '/candidate/web/uploadAvatar',
        method: 'POST',
        body: params
    });
}

//学校名称查询
export async function querySchool(params) {
    return XHR({
        url: prefix + '/common/school/web/query?name='+params,
        method: 'GET',
    });
}

//保存候选人报告模版
export async function saveReportTem(params) {
    return XHR({
        url: prefix + '/candidate/reportTemplate/web/save',
        method: 'POST',
        body: params
    });
}

//分页查找候选人报告模版
export async function queryReportTem(params) {
    return XHR({
        url: prefix + '/candidate/reportTemplate/web/query/page',
        method: 'POST',
        body: params
    });
}

//查找候选人报告模版
export async function reportTemDet(params) {
    return XHR({
        url: prefix + '/candidate/reportTemplate/web/query/'+params,
        method: 'GET',
    });
}

//模板状态变更
export async function changeStatus(params) {
    return XHR({
        url: prefix + '/candidate/reportTemplate/web/changeStatus'+params,
        method: 'GET',
    });
}

//团队树形结构
export async function groupList() {
    return XHR({
        url: prefix + '/authcenter/group/web/treeList',
        method: 'GET',
    });
}

// 查询职位候选人明细表
export async function getPosCanReport(params) {
    return XHR({
        url: prefix + '/position/web/getPositionCandidateReport',
        method: 'POST',
        body: params
    });
}

// 导出职位候选人明细表
export async function expExcelPosCan(params) {
    return XHR({
        url: prefix + '/position/web/exportExcelPositionCandidateReport',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

// 查询职位周报表
export async function getPosWeekReport(params) {
    return XHR({
        url: prefix + '/position/web/getPositionWeekReport',
        method: 'POST',
        body: params
    });
}

// 导出职位周报表
export async function expExcelPosWeek(params) {
    return XHR({
        url: prefix + '/position/web/exportExcelPositionWeekReport',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

// 查询BU,Team和SubTeam
export async function getGroupLevel(params) {
    return XHR({
        url: prefix + '/authcenter/group/web/getGroupLevel',
        method: 'POST',
        body: params,
    });
}

// 查询BU,Team和SubTeam下的所有的员工
export async function getGroupEmployee(params) {
    return XHR({
        url: prefix + '/authcenter/account/web/getGroupEmployee',
        method: 'POST',
        body: params,
    });
}

// 根据职位id和候选人id查询当前流程状态
export async function queryPosCanStatus(params) {
    return XHR({
        url: prefix + '/position/web/queryPositionCandidateStatus',
        method: 'GET',
        body: params,
    });
}

// 接收营销邮件
export async function receiveMail(params) {
    return XHR({
        url: prefix + '/candidate/web/receiveMail',
        method: 'GET',
        body: params,
    });
}

//文件预览（如简历，图片）
export async function downResumeCont(params) {
    return XHR({
        url: prefix + '/common/web/content',
        method: 'GET',
        body: params,
    });
}

//下载文件
export async function downloadResume(params) {
    return XHR({
        url: prefix + '/common/web/download',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

//简历重解析
export async function parseResumeUpdate(params) {
    return XHR({
        url: prefix + '/candidate/web/parseResumeUpdate'+params,
        method: 'POST',
    });
}

// 重复候选人添加新简历附件
export async function uploadDuplicateResume(params1, params2) {
    return XHR({
        url: prefix + '/candidate/web/uploadDuplicateResume'+params2,
        method: 'POST',
        body: params1,
        dataType:'json',
    });
}

//工作日报列表
export async function getWorkWeekDayReport(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/getWorkWeekDayReport',
        method: 'POST',
        body: params
    });
}

//导出工作日报列表
export async function exportWorkWeekDayReport(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/exportWorkWeekDayReport',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

//导出工作日报明细报表
export async function exportWeekDayReportDetail(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/exportWeekDayReportDetail',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

//工作日报列表
export async function singleWorkWeekDayReportDetail(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/singleWorkWeekDayReportDetail',
        method: 'POST',
        body: params
    });
}

//Placement/Invoiced报表
export async function placementInvoiced(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/placementInvoiced',
        method: 'POST',
        body: params
    });
}

//Placement/Invoiced报表明细
export async function singlePlacementInvoiced(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/singlePlacementInvoiced',
        method: 'POST',
        body: params
    });
}

//导出Placement/Invoiced报表
export async function exportPlacementInvoiced(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/exportPlacementInvoiced',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

//导出Placement/Invoiced报表明细
export async function exportPlacementInvoicedDetail(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/exportPlacementInvoicedDetail',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

//职位日常运营列表
export async function positionDailyOperation(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/positionDailyOperation',
        method: 'POST',
        body: params
    });
}

//导出职位日常运营报表
export async function exportPositionDailyOperation(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/exportPositionDailyOperation',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

//根据条件查询向登录人汇报的所有人汇报线信息（团队候选人汇报线）
export async function getAccountReporters(params) {
    return XHR({
        url: prefix + '/authcenter/group/web/getAccountReporters',
        method: 'GET',
        body: params
    });
}

//根据条件查询向登录人汇报的所有人汇报线信息（报表汇报线）
// /authcenter/group/web/getAccountReportersIncludeSelf
export async function getAccountReportersPullDown(params) {
    return XHR({
        url: prefix + '/authcenter/group/web/getAccountReportersPullDown',
        method: 'GET',
        body: params
    });
  }
//导出职位日常运营明细报表
export async function exportPositionDailyOperationDetail(params) {
    return XHR({
        url: prefix + '/position/dailyOperationDetail/web/exportPositionDailyOperationDetail',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}
//查询日常候选人报表
export async function getCandidateDailyReport(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/getCandidateDailyReport',
        method: 'POST',
        body: params
    });
}

//导出候选人日常运营报表
export async function eptCandDailyOperation(params) {
    return XHR({
        url: prefix + '/authcenter//dailyOperation/web/exportCandidateDailyOperation',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

//导出候选人日常运营明细报表
export async function exportCandidateDailyOperationDetail(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/exportCandidateDailyOperationDetail',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}
//单个职位日常运营明细报表
export async function singlePositionDailyOperationDetail(params) {
    return XHR({
        url: prefix + '/position/dailyOperationDetail/web/singlePositionDailyOperationDetail',
        method: 'POST',
        body: params
    });
}

//服务费合计
export async function singleTotalFee(params) {
    return XHR({
        url: prefix + '/position/dailyOperationDetail/web/singleTotalFee',
        method: 'POST',
        body: params
    });
}

//查询日常候选人明细报表
export async function getCanDayDetReport(params) {
    return XHR({
        url: prefix + '/authcenter/dailyOperation/web/getCandidateDailyDetailReport',
        method: 'POST',
        body: params
    });
}

//删除草稿候选人
export async function delDraftCandidate(candidateId) {
    return XHR({
        url: prefix + '/candidate/web/delDraft/'+candidateId,
        method: 'GET',
    });
}

//管理员报表下拉
export async function getManagerPullDown(params) {
    return XHR({
        url: prefix + '/authcenter/group/web/getManagerPullDown',
        method: 'GET',
        body: params
    });
}

//判断是否有权限显示报表offer和weekly
export async function getReportGroups(params) {
    return XHR({
        url: prefix + '/authcenter/group/web/getReportGroups',
        method: 'GET',
        body: params
    });
}

//查询过滤的职位(用于前面2张报表,offer detail和weeky open requisition report total)
export async function getFilterGroup(params) {
    return XHR({
        url: prefix + '/position/web/getFilterGroup',
        method: 'GET',
        body: params
    });
}

//保存客户信息
export async function saveCustomer(params) {
    return XHR({
        url: prefix + '/position/customer/web/save',
        method: 'POST',
        body: params
    });
}

// // 分页查询客户信息
// export async function findCustomerList(params) {
//     return XHR({
//         url: prefix + '/position/customer/web/findCustomerList',
//         method: 'POST',
//         body: params
//     });
// }

// // 查询客户拜访列表
// export async function findCustomerVisitList(params) {
//     return XHR({
//         url: prefix + '/position/customer/web/findCustomerVisitList',
//         method: 'POST',
//         body: params
//     });
// }

// 查询工商库客户信息
export async function findInQxById(params) {
    return XHR({
        url: prefix + '/position/customer/web/findInQxById/'+params,
        method: 'GET',
    });
}

// 查询工商库客户信息
export async function findInQxByName(params) {
    return XHR({
        url: prefix + '/position/customer/web/findInQxByName/'+params,
        method: 'GET',
    });
}

// 编辑客户信息
export async function updateCustomer(params) {
    return XHR({
        url: prefix + '/position/customer/web/update',
        method: 'POST',
        body: params
    });
}

//保存客户联系人
export async function saveCustomerContact(params) {
    return XHR({
        url: prefix + '/position/customer/web/saveCustomerContact',
        method: 'POST',
        body: params
    });
}

// 查询客户联系人列表
export async function findCustomerContactList(params) {
    return XHR({
        url: prefix + '/position/customer/web/findCustomerContactList',
        method: 'POST',
        body: params
    });
}

// 保存客户地址
export async function saveCustomerAddress(params) {
    return XHR({
        url: prefix + '/position/customer/web/saveCustomerAddress',
        method: 'POST',
        body: params
    });
}

// 查询客户联系人详情
export async function findCustomerContactDetail(params) {
    return XHR({
        url: prefix + '/position/customer/web/findCustomerContactDetail',
        method: 'GET',
        body: params
    });
}

// 查询客户地址列表
export async function findCustomerAddressList(params) {
    return XHR({
        url: prefix + '/position/customer/web/findCustomerAddressList',
        method: 'POST',
        body: params
    });
}

// 保存拜访信息
export async function saveCustomerVisit(params) {
    return XHR({
        url: prefix + '/position/customer/web/saveCustomerVisit',
        method: 'POST',
        body: params
    });
}

// 查询客户拜访列表
export async function findCustomerVisitList(params) {
    return XHR({
        url: prefix + '/position/customer/web/findCustomerVisitList',
        method: 'POST',
        body: params
    });
}

//获取产品介绍字段列表
export async function getProductInfo() {
    return XHR({
        url: prefix + '/position/customer/web/getProductInfo',
        method: 'GET',
    });
}

//查询拜访跟进信息
export async function findCustomerVisitDetail(params) {
    return XHR({
        url: prefix + '/position/customer/web/findCustomerVisitDetail',
        method: 'GET',
        body: params
    });
}

// 根据id查询拜访信息
export async function findCustomerVisitInfo(params) {
    return XHR({
        url: prefix + '/position/customer/web/findCustomerVisit',
        method: 'GET',
        body: params
    });
}

// 客户公司报表列表
export async function customerReport(params) {
    return XHR({
        url: prefix + '/position/customerReport/web/customerReport',
        method: 'POST',
        body: params
    });
}

// GET /customerReport/web/exportCustomerReport
// 导出客户公司报表
export async function exportCustomerReport(params) {
    return XHR({
        url: prefix + '/position/customerReport/web/exportCustomerReport',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

// 单个客户公司明细报表
export async function singleCustomerReportDetail(params) {
    return XHR({
        url: prefix + '/position/customerReport/web/singleCustomerReportDetail',
        method: 'POST',
        body: params
    });
}

// 导出客户公司明细报表
export async function exportCustomerReportDetail(params) {
    return XHR({
        url: prefix + '/position/customerReport/web/exportCustomerReportDetail',
        method: 'GET',
        body: params,
        dataType: 'download'
    });
}

// 验证客户联系人
export async function checkCustomerContactExist(params) {
    return XHR({
        url: prefix + '/position/customer/web/checkCustomerContactExist',
        method: 'POST',
        body: params
    });
}

// 查询有效客户联系人数量
export async function findEffectCustomerContactCount(params) {
    return XHR({
        url: prefix + '/position/customer/web/findEffectCustomerContactCount',
        method: 'GET',
        body: params,
    });
}
// 生成模板（报告预览）
export async function fetchGenerateReport(params) {
    return XHR({
        url: prefix + "/candidate/candidateReport/web/generateReport",
        method: 'POST',
        body: params
    });
}

// 模板选择列表
export async function fetchGetAllTemplateOfLanguage(params) {
    return XHR({
        url: prefix + "/candidate/candidateReport/web/getAllTemplateOfLanguage",
        method: 'POST',
        body: params
    });
}

// 查询候选人详情
export async function fetchCandidateByChannelId(params) {
    return XHR({
        url: prefix + '/candidate/web/channel/candidate/query',
        method: 'GET',
        body: params
    });
}

// 根据鹰眼id查询鹰眼候选人Id
export async function fetchCandidateEagle(params) {
    return XHR({
        url: prefix + '/candidate/web/channel/candidate/query/eagle',
        method: 'GET',
        body: params
    });
}
