import React, {PureComponent} from "react";
// import {Link,Redirect} from "react-router-dom";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
// import {Layout, Menu, Icon, Dropdown, Avatar,Badge} from "antd";
import {Layout, Menu, Dropdown, Icon, Modal, Calendar } from "antd";
import Authorized from "utils/authorized";
import BaseMenu from "components/header/menu/base-menu";
import HeaderMenu from "components/header/header-menu";
// import LegalDialog from "components/legal-dialog";
import Content from "./content";
import layoutWrapper from "./layout-wrapper";
import style from "./header-nav-layout.scss";
import userModel from "../store/reducers/user";
import moment from 'moment';
import {showPageTitle, getZhName} from '../utils/util'
import {workPlanCalendar} from '../services/api3'
const {Header: AntHeader} = Layout;
const Logo = () => {
  return <img className={style.header_logo} src={require('../assets/imgs/logo_1.png')} alt=""/>;
};
@connect(({user}) => ({...user}), {...userModel.actions})
export default layoutWrapper()(
  class HeaderNavLayout extends PureComponent {
    render() {
      const {
        className,
        children,
        location,
        route,
        menuData,
        // userInfo
      } = this.props;
      return (
        <Authorized
          authority={route.authority}
          noMatch={<Redirect to="/user/login"/>}
        >
          <Layout className={`${style['header-nav-layout']} ${className || ""}`}>
            <Layout
              style={{
                minHeight: "100vh"
              }}
              // className="layout-content"
            >
              <Header menuData={menuData} {...this.props} showPageTitle={showPageTitle}/>
              <Content
                route={route}
                location={location}
                className={style["header-nav-layout-content"]}>
                {children}
              </Content>
              {/* {!userInfo.guideCount && <LegalDialog/>} */}
              {/*<p className={style.copyright} align="center">Copyright © 2015 Career International Consulting</p>*/}
            </Layout>
          </Layout>
        </Authorized>
      );
    }
  }
);

class Header extends PureComponent {
  componentDidMount() {
    this.props.showPageTitle(this.props.history.location.pathname);
  }

  componentWillReceiveProps(nextProps) {
    nextProps.showPageTitle(nextProps.history.location.pathname);
  }

  render() {
    const {menuData, roles} = this.props;
    // const moreList = (<Menu>
    //   <Menu.Item>
    //     <a target="_blank" rel="noopener noreferrer"
    //        href="http://erp.careerintlinc.com/SitePages/Recruitment/Myposition.aspx?selectpanel=p1&selectitem=10">
    //       <span>旧版职位</span>
    //     </a>
    //   </Menu.Item>
    //   <Menu.Item>
    //     <a target="_blank" rel="noopener noreferrer" href="http://track.careerintlinc.com:1238/">
    //       <span>旧版候选人</span>
    //     </a>
    //   </Menu.Item>
    //   <Menu.Item>
    //     <a target="_blank" rel="noopener noreferrer"
    //        href="http://erp.careerintlinc.com/_layouts/15/Career.Workflow2013/Report.aspx?module=My%20Report%20Center">
    //       <span>旧版报表</span>
    //     </a>
    //   </Menu.Item>
    // </Menu>);
    const candidateDetHead = this.props.history.location.pathname && this.props.history.location.pathname.split('/')[1];
    return (
      <AntHeader style={{padding: 0}}>
        <div style={{marginLeft: 'calc(100vw - 100%)'}}>
          <div id="header">
            <Logo/>
            <BaseMenu
              menuData={menuData}
              roles={roles}
              location={this.props.location}
              history={this.props.history}
              mode="horizontal"
            />
            {/* <Dropdown overlay={moreList} className="ml10">
              <span className="header_more">
                <i className={`iconfont icon-gengduo mr4`}/>更多 <Icon type="down"/>
              </span>
            </Dropdown> */}
            <CustomHeader {...this.props}/>
          </div>
        </div>
        <div id="header_menu" style={{paddingLeft:'calc(100vw - 100%)'}} className={candidateDetHead === 'candidateDetail' ? 'candidateDetailHead':''}>
          <HeaderMenu menuData={menuData} location={this.props.location} history={this.props.history}/>
        </div>
      </AntHeader>
    );
  }
}

class CustomHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      calenderTitle: moment().format("YYYY年MM月"),
      toYear:moment().format("YYYY"),
      toMonth:moment().format("MM"),
      calenderList: [],
      calendarIsshow: false
    }
  }
  //重新登录
  loginOut = () => {
    const {logout} = this.props;
    Modal.confirm({
      title: '确定退出登录？',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        logout();
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  };
  //修改密码
  updatePassword = () => {
    localStorage.setItem('recordUrl', '/' + window.location.hash);
    window.location.href = '/#/user/password'
  };
  onClickMenu = ({key}) => {
    switch (key) {
      case 'password':
        this.updatePassword();
        break;
      case 'logout':
        this.loginOut();
        break;
      default:
        break;

    }
  };
  showCalendar = (type) => {
    this.setState({
      calendarIsshow: type,
      toYear:moment().format("YYYY"),
      toMonth:moment().format("MM"),
    })
    if(type === true){
      this.getWorkPlan({
        startTime:moment().startOf("month").format("YYYY-MM-DD"),
        endTime:moment().endOf("month").format("YYYY-MM-DD")
      })
    }
  }
  getWorkPlan = (param) => {
    let {startTime, endTime} = param
    let preMonth = moment(startTime).subtract(1,'months').endOf('month').format("YYYY-MM-DD")
    let nextMonth = moment(endTime).subtract(-1,'months').startOf('month').format("YYYY-MM-DD")
    param.startTime = moment(preMonth).subtract(14, "days").format("YYYY-MM-DD")
    param.endTime = moment(nextMonth).subtract(-14, "days").format("YYYY-MM-DD")
    workPlanCalendar(param).then(res => {
      if(res.code === 200){
        this.setState({
          calenderList: res.result
        })
      }
    })
  }
  dateCellRender = (date) => {
    let {calenderList} = this.state
    return (
      <div>
        {
          calenderList.map((item) => {
            if(moment(date).format("YYYY-MM-DD") === moment(item.planTime).format("YYYY-MM-DD")){
              return(
                <a 
                  key={item.uid} 
                  title={item.topic === 99 ?
                    item.content && item.content
                    :
                    (item.content && item.content.split('@')[0] && item.content.split('@')[0])
                  } 
                  className="calenderItem" 
                  target="_blank" 
                  href={item.topic !== 99 && item.content && item.content.split('@')[1] && `${window.location.origin}/${item.content.split('@')[1]}`} 
                >
                  {item.topic === 99 ?
                    item.content && item.content
                    :
                    (item.content && item.content.split('@')[0] && item.content.split('@')[0])
                  }
                </a>
              )
            }else{
              return null
            }
          })
        }
      </div>
    )
  }
  dateChange = (date) => {
    let startTime = moment(date).startOf("month").format("YYYY-MM-DD")
    let endTime = moment(date).endOf("month").format("YYYY-MM-DD")
    this.setState({
      calenderTitle: moment(date).format("YYYY年MM月"),
      toYear:moment(date).format("YYYY"),
      toMonth:moment(date).format("MM"),
    },()=>{
      this.getWorkPlan({
        startTime,
        endTime
      })
    })
  }
  render() {
    const {userInfo} = this.props;
    let {calenderTitle, calendarIsshow} = this.state
    const menu = (
      <Menu selectedKeys={[]} onClick={this.onClickMenu}>
        <Menu.Item key="password">
          <Icon type="lock" className="mr5"/>
          修改密码
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
          <Icon type="logout" className="mr5"/>
          退出登录
        </Menu.Item>
      </Menu>
    );
    // console.log('userInfo', userInfo)
    // const {name, avatar, logout} = this.props;
    // const menu = (
    //   <Menu selectedKeys={[]}>
    //     <Menu.Item key="userCenter">
    //       <Link to="/center">
    //         <Icon type="user" className="mr5"/>
    //         个人中心
    //       </Link>
    //     </Menu.Item>
    //     <Menu.Divider />
    //     <Menu.Item key="logout">
    //       <span onClick={logout}>
    //         <Icon type="logout" className="mr5"/>
    //         退出登录
    //       </span>
    //     </Menu.Item>
    //   </Menu>
    // );
    // window.location.href = '/#/user/login';
    let showName;
    if (userInfo.roleType === 'superadmin') {
      if (userInfo.bindAccount) {
        // showName = `${userInfo.englishName} (${userInfo.name}) （${userInfo.bindAccount.englishName} (${userInfo.bindAccount.name})）`;
        showName = `${getZhName(userInfo.englishName, userInfo.name)} (${getZhName(userInfo.bindAccount.englishName, userInfo.bindAccount.name)})`;
      } else {
        window.location.href = '/#/user/login';
      }
    } else {
      // showName = `${userInfo.englishName} (${userInfo.name})`;
      showName = `${getZhName(userInfo.englishName,userInfo.name)}`;
    }
    return (
      <React.Fragment>
        <div className="right">
          <a style={{verticalAlign:'bottom',marginRight:30, color:'#333'}} onClick={() => this.showCalendar(true)}>
            我的日历
          </a>
          <Dropdown overlay={menu}>
            <span className="name vam">{showName} <Icon className="fs20" type="down"/></span>
            {/*<Avatar*/}
            {/*size="small"*/}
            {/*className="mr10"*/}
            {/*src={avatar}*/}
            {/*alt="avatar"*/}
            {/*/>*/}
            {/*<span className="name">{name}</span>*/}
            {/*</span>*/}
          </Dropdown>
          {/*<span className="name vam mr10">{showName}</span>*/}
          {/*<Popconfirm placement="bottomRight" title="确定退出登录?" onConfirm={logout} okText="确定" cancelText="取消">*/}
          {/*  <span className={`cp color_text vam ${style.logout_icon}`} title="退出登录">*/}
          {/*  <i className="iconfont icon-poweroff fs18"/>*/}
          {/*</span>*/}
          {/*</Popconfirm>*/}

          {/*<Badge count={11} overflowCount={9}>*/}
          {/*<i className="iconfont icon-bell vam fs20"/>*/}
          {/*</Badge>*/}
          {/*<Dropdown overlay={menu}>*/}
          {/*<span className="ml20">*/}
          {/*<Avatar*/}
          {/*size="small"*/}
          {/*className="mr10"*/}
          {/*src={avatar}*/}
          {/*alt="avatar"*/}
          {/*/>*/}
          {/*<span className="name">{name}</span>*/}
          {/*</span>*/}
          {/*</Dropdown>*/}
          <Modal 
            className="headerPlan" 
            style={{minWidth:1200,minHeight:780}} 
            title={calenderTitle} 
            visible={calendarIsshow} 
            footer={null}
            onCancel={() => this.showCalendar(false)}
          >
            {calendarIsshow ? <Calendar
              dateCellRender={this.dateCellRender}
              onChange={this.dateChange}
            /> : ''}
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
