/**
 * Created by CI11840 on 2019/3/1.
 */
import Model from "../model";
import {
  createPosition,
  upDatePosition,
  queryPositionList,
  queryPositionInfo,
  queryPositionOtherFields,
  findCustomerList,
  getPositionAccounts,
  getAccountsInIds,
  getGroupsByAccountId,
  queryPositionFlows,
  queryPositionFlowDetail,
  queryReportTemplates,
  deptTreeList,
  getAccountOfCurAndChildGroup,
  getPositionFlowProgress,
  getCandidatesByStatus,
  getCandidatesActivities,
  getCandidateForm,
  saveFlowNodes,
  updateFlowNode,
  getPositionAdvisers,
  updatePositionStatus,
  updatePositionGroup,
  getCounterpartListOfPositionId,
  updateAssignCounterpartBatch,
  batchAddCounterpart,
  searchPositionAdvisers,
  getAdviserListOfPositionId,
  assignAdviserBatch,
  multiPositionAssignAdviserBatch,
  transferAdviser,
  transferCandidates,
  searchCandidatesByAdviserId
} from '../../services/api1'
import {findCustomerContactList} from '../../services/api2'
export default Model.getInstance(
  class extends Model {
    namespace = "position";

    state = {
      posSearchModel: {visible: false},//职位高级搜索弹框
      posCanSearchModel: {visible: false},//可匹配人选搜索弹框
      newPositionModel: {visible: false, step: 1, confirmLoading: false, storageLoading: false},//新建职位弹框
      editPositionModel: {visible: false, step: 1, confirmLoading: false},//编辑职位弹框
      positionDetailDrawer: {visible: false},//职位流程弹框
      positionInfoDrawer: {visible: false},//职位信息弹框
      makePositionModel: {visible: false},//职位流程操作流程弹框
      positionStatusList: [
        {id: 1, name: '进行中', type: 1},
        {id: 2, name: '已完成', type: 2},
        {id: 3, name: '已暂停', type: 3},
        {id: 4, name: '已取消', type: 4},
      ],
      positionDetailPStepState: {},//职位流程中的人选状态分类
      positionDetailPState: 1,//1进行中2已完成3已出局4已开票5全部
      positionDetailPStep: {},//选中的职位流程 1待筛选2沟通准备3待初审4客户筛选5面试流程6offer流程7入职流程8保证期
      positionTempFields: {isChange: false},//新建职位自定义模板数据
      positionListData: {page: {}},//职位列表查询的数据
      positionInfoData: {isChange: false},//职位信息详情
      positionOtherInfoData: {isChange: false},//获取职位详情额外字段
      customerListInfo: {records: [], loading: false},//客户信息列表
      contactListInfo: {records: [], loading: false},//客户联系人列表
      positionAccounts: {
        records: [], loading: false
      },//职位所有账号
      positionCounterparts: {
        records: [], loading: false
      },//对接人所有账号
      onSites: {
        records: [], loading: false
      },//onSites所有账号
      BDStaffs: {
        records: [], loading: false
      },//BDStaffs所有账号
      otherAccountsEmail: {
        records: [], loading: false
      },//email所有账号
      selectedCustomer: [],//选中的客户
      selectedContact: [],//选中的客户联系人
      selectedBdStaff: [],//选中的BD人员
      groupsByAccount: [],//查询某个人的汇报线
      positionFlows: [],//职位流程列表
      positionFlowDetail: {},//查找单个职位流程
      reportTemplates: [],//候选人报告模板
      deptTreeLists: [],//团队部门树形结构
      staffByDeptList: [],//根据部门查询员工列表
      positionStateCounter: {},//获取职位状态数
      positionFlowProgressCounter: [],//获取职位流程进展状态数
      candidatesByStatusList: {records: []},//获取流程节点对应候选人列表
      positionDetailOwnerState: '1',//1我的候选人，2全部候选人,3我的团队候选人
      candidatesActivitiesList: {records: []},//获取流程节点对应候选人活动列表
      candidateInfoActivitiesList: {records: []},//候选人详情候选人活动列表
      positionDetailSelectedCandidate: {//选中的人选
        selectedRowKeys: [],//选中的人选的信息
        selectedCandidateUid: '',//查看的人选的id
        selectedCandidate: {},//查看的人选的id
      },
      candidateFormInfo: null,//获取流程节点各个表单的初始化数据
      positionAdvisersList: [],//根据职位查询招聘顾问列表
      selectedCounterpart: {
        selectedCounterpartList: [],//列表
        selectedCounterpartListId: [],//列表ID
        initSelectedCounterpartListId: [],//编辑选中的交付对接人时，初始选中的对接人
      },//选中的交付对接人
      // positionAdvisersOfCounterpart: [],//根据职位和交接人查询到的顾问列表
      // candidatesByAdviserIdList: [],//根据职位和顾问查询到的候选人列表
      selectedCounterpartInfo: {
        counterpartUid: [],
        counterparts: [],
      },//选中招聘顾问信息
      transferDialogModel: {//交付顾问和交付对接人移交弹框
        visible: false
      },
      positionListLoading: {spinning: false, tip: '加载中...'},//职位列表加载状态
      positionMatchCandidateLoading: {spinning: false, tip: '加载中...'},//职位匹配候选人加载状态
      invoiceLoading: {spinning: false, tip: '数据处理中...'},
    };

    actions = {
      //新建职位
      async createPositionSave(data){
        let upDateResult = {};
        await  createPosition(data).then(res => {
          upDateResult = res;
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/showNewPositionModel",
              payload: {visible: false, confirmLoading: false, storageLoading: false}
            });
            this.dispatch({
              type: "position/showEditPositionModel",
              payload: {visible: false, confirmLoading: false}
            });
          } else {
            this.dispatch({
              type: "position/showNewPositionModel",
              payload: {confirmLoading: false, storageLoading: false}
            });
            this.dispatch({
              type: "position/showEditPositionModel",
              payload: {confirmLoading: false}
            });
          }
        });
        return upDateResult;
      },
      //更新职位
      async upDatePositionSave(data){
        let upDateResult = {};
        await upDatePosition(data).then(res => {
          upDateResult = res;
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/showNewPositionModel",
              payload: {visible: false, confirmLoading: false, storageLoading: false}
            });
            this.dispatch({
              type: "position/showEditPositionModel",
              payload: {visible: false, confirmLoading: false}
            });
          } else {
            this.dispatch({
              type: "position/showNewPositionModel",
              payload: {confirmLoading: false, storageLoading: false}
            });
            this.dispatch({
              type: "position/showEditPositionModel",
              payload: {confirmLoading: false}
            });
          }
        });
        return upDateResult;
      },
      //职位列表查询
      queryPositionListData(data){
        queryPositionList(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/searchPositionList",
              payload: res.result
            });
          }
          this.dispatch({
            type: "position/changePositionListLoading",
            payload: {spinning: false}
          });
        });
      },
      //查看职位信息
      async queryPositionData(data, type){
        let infoData = {};
        await queryPositionInfo(data).then(res => {
          infoData = res;
          if (res && res.code === 200) {
            if (type === 1) {
              // this.dispatch({
              //   type: "position/onChangePositionTempFields",
              //   payload: res.result
              // });
              this.dispatch({
                type: "position/searchPositionInfo",
                payload: {...res.result, isModify: true}
              });
            } else {
              let resultData = {};
              if (res.result && res.result.positionStatus === 0 && res.result.step < 3) {
                resultData = {...res.result, isCooperativeDeliver: true}
              } else {
                resultData = res.result;
              }
              this.dispatch({
                type: "position/searchPositionInfo",
                payload: {...resultData, isModify: true}
              });
            }
          }
        });
        return infoData;
      },
      //获取职位详情额外字段
      queryPositionOtherData(data){
        queryPositionOtherFields(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/searchPositionOtherFields",
              payload: {...res.result, isModify: true}
            });
          }
        });
      },
      //查询客户信息
      queryCustomerList(data){
        this.dispatch({
          type: "position/searchCustomerList",
          payload: {loading: true}
        });
        findCustomerList(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/searchCustomerList",
              payload: {...res.result, loading: false}
            });
          }
        });
      },
      //查询客户联系人
      queryContactList(data){
        this.dispatch({
          type: "position/searchContactList",
          payload: {loading: true}
        });
        findCustomerContactList(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/searchContactList",
              payload: {...res.result, loading: false}
            });
            // if (data.uid) {
            //   this.dispatch({
            //     type: "position/changeSelectedContact",
            //     payload: res.result ? res.result.records : []
            //   });
            // }
          }
        });
      },
      //查询获取所有账号
      queryPositionAccounts(data, type){
        let selectType;
        switch (type) {
          case 0:
            selectType = 'searchPositionAccounts';//职位主管
            break;
          case 1:
            selectType = 'searchCounterparts';//交付对接人
            break;
          case 2:
            selectType = 'searchOnSites';//onsite人员
            break;
          case 3:
            selectType = 'searchBDStaff';//BD人员
            break;
          case 4:
            selectType = 'searchOtherAccountsEmail';
            break;
          default:
            selectType = 'searchPositionAccounts';
            break;
        }
        this.dispatch({
          type: `position/${selectType}`,
          payload: {loading: true}
        });
        getPositionAccounts(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: `position/${selectType}`,
              payload: {records: res.result, loading: false}
            });
          }
        });
      },
      //根据id集合查询所有账号
      async queryAccountsInIds(data, type,list){
        let selectType, selectedData;
        switch (type) {
          case 0:
            selectType = 'searchPositionAccounts';//职位主管
            break;
          case 1:
            selectType = 'searchCounterparts';//交付对接人
            selectedData = 'changeSelectedCounterpart';
            break;
          case 2:
            selectType = 'searchOnSites';//onsite人员
            break;
          case 3:
            selectType = 'searchBDStaff';//BD人员
            selectedData = 'changeSelectedBdStaff';
            break;
          case 4:
            selectType = 'searchOtherAccountsEmail';
            // selectedData = 'changeSelectedBdStaff';
            break;
          default:
            selectType = 'searchPositionAccounts';
            // selectedData = 'changeSelectedBdStaff';
            break;
        }
        let resultData = [];
        await getAccountsInIds(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: `position/${selectType}`,
              payload: {records: res.result, loading: false}
            });
            if (type === 3) {
              this.dispatch({
                type: `position/${selectedData}`,
                payload: res.result
              });
            } else if (type === 1) {
              const arr = [];
              const listObj={};
              const results=[];
              list && list.forEach(item=>{
                  listObj[item.accountId]=item;
              });
              res.result && res.result.forEach(item => {
                arr.push(item.uid);
                results.push({...item,openPerm:listObj[item.uid]&&listObj[item.uid].openPerm})
              });
              this.dispatch({
                type: `position/${selectedData}`,
                payload: {
                  selectedCounterpartList: results || [],//列表
                  selectedCounterpartListId: arr || [],//列表ID
                }
              });
              resultData = arr;
            }
          }
        });
        return resultData;
      },
      //查询某个人的汇报线
      async queryGroupsByAccount(accountId, type, reporterId = null){
        let list = [];
        await getGroupsByAccountId(accountId, reporterId).then(res => {
          if (res && res.code === 200) {
            list = res.result;
          }
        });
        if (type === 0) {
          this.dispatch({
            type: "position/searchGroupsByAccount",
            payload: list || []
          });
        }
        return list || []
      },
      //查找职位流程列表
      queryPositionFlowsList(flowId){
        let id = flowId && {flowId};
        queryPositionFlows(id).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/searchPositionFlows",
              payload: res.result
            });
          }
        });
      },
      //查找单个职位流程
      queryPositionFlowInfo(data){
        queryPositionFlowDetail(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/searchPositionFlowDetail",
              payload: res.result
            });
          }
        });
      },
      //查找候选人报告模板列表
      queryReportTemplatesList(){
        queryReportTemplates().then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/searchReportTemplatesList",
              payload: res.result
            });
          }
        });
      },
      //团队树形结构
      queryDeptTreeList(){
        deptTreeList().then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/searchDeptTreeList",
              payload: res.result
            });
          }
        });
      },
      //根据团队id查找当前团队及子团队的所有员工
      queryStaffByDeptId(data){
        getAccountOfCurAndChildGroup(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/changeStaffByDeptList",
              payload: res.result
            });
          }
        });
      },
      //获取流程状态数量
      async getPositionFlowProgressCounter(allData){
        const {isReset, positionDetailPStepState, positionDetailPStep, positionDetailPState, steps, ...data} = allData;
        await getPositionFlowProgress(data).then(res => {
          if (res && res.code === 200) {
            if (data.level === 1) {
              this.dispatch({
                type: "position/changePositionStateCounter",
                payload: res.result
              });
              if (isReset) {
                this.dispatch({
                  type: "position/onChangePositionDetailPState",
                  payload: {type: (steps&&steps.topStatus)|| 1}
                });
              }
            } else if (data.level === 2) {
              this.dispatch({
                type: "position/changePositionFlowProgressCounter",
                payload: res.result
              });
              let step = res.result[0] || {};
              if (isReset) {
                if (steps&&steps.progressStep) {
                  res.result.forEach(item=>{
                    if(item.uid===steps.progressStep){
                      step = item;
                      this.dispatch({
                        type: "position/onChangePositionDetailPStep",
                        payload: {...item, id: item.uid, key: item.number, type: item.uid}
                      });
                      if (item.childList && item.childList.length > 0) {
                        let childItem = null;
                        item.childList.forEach(cItem=>{
                          if(cItem.uid===steps.progressStepChild){
                            childItem=cItem;
                          }
                        })
                        this.dispatch({
                          type: "position/onChangePositionDetailPStepState",
                          payload: childItem || item.childList[0]
                        });
                      } else {
                        this.dispatch({
                          type: "position/onChangePositionDetailPStepState",
                          payload: item
                        });
                      }
                    }
                  })
                } else {
                  this.dispatch({
                    type: "position/onChangePositionDetailPStep",
                    payload: {...step, id: step.uid, key: step.number, type: step.uid}
                  });
                  if (step.childList && step.childList.length > 0) {
                    this.dispatch({
                      type: "position/onChangePositionDetailPStepState",
                      payload: step.childList[0]
                    });
                  } else {
                    this.dispatch({
                      type: "position/onChangePositionDetailPStepState",
                      payload: step
                    });
                  }
                }
                function getUid(step, childUid) {
                  if (step.childList && step.childList.length > 0) {
                    let isHave=false;
                    step.childList.forEach(item=>{
                      (item.uid===childUid) && (isHave=true);
                    })
                    return isHave?childUid: step.childList[0].uid;
                  } else {
                    return step.uid
                  }
                }

                let positionFlowCondition = {
                  limit: 10,
                  page: 1,
                  owner: data.owner,
                  positionUid: data.positionId,
                  searchKey: '',
                  status: isReset ? getUid(step,steps&&steps.progressStepChild) : positionDetailPStepState.uid,
                  topStatus: isReset ?(steps&&steps.topStatus)|| 1 : positionDetailPState
                };
                getCandidatesByStatus(positionFlowCondition).then(response => {
                  if (response.code === 200) {
                    this.dispatch({
                      type: "position/changeCandidatesByStatusList",
                      payload: response.result
                    });
                    if (response.result.records && response.result.records[0]) {
                      this.dispatch({
                        type: "position/changePositionDetailSelectedCandidate",
                        payload: {//选中的人选
                          selectedRowKeys: [],//选中的人选的信息
                          selectedCandidateUid: response.result.records[0].candidateId,//查看的人选的id
                          selectedCandidate: response.result.records[0],//查看的人选详情
                        }
                      });
                      let positionCandidateActivityCondition = {
                        candidateIds: [response.result.records[0].candidateId], //选择的候选人id ,
                        currentActivity: 1,//是否是当前活动(1-我的活动,2-全部活动) ,
                        positionId: data.positionId,// 职位id ,
                        pageIndex: 1,// 当前页码,从1开始 ,
                        pageLimit: 10,// 返回大小 ,
                      };
                      this.dispatch({
                        type: "position/changeCandidatesActivities",
                        payload: {loading: true}
                      });
                      getCandidatesActivities(positionCandidateActivityCondition).then(response1 => {
                        if (response1.code === 200) {
                          this.dispatch({
                            type: "position/changeCandidatesActivities",
                            payload: response1.result ? {...response1.result, loading: false} : {
                              current: 0,
                              pages: 0,
                              size: 10,
                              total: 0,
                              records: [],
                              loading: false
                            }
                          });
                        }
                      });
                    } else {
                      this.dispatch({
                        type: "position/changeCandidatesActivities",
                        payload: {
                          current: 0,
                          pages: 0,
                          size: 10,
                          total: 0,
                          records: [],
                          loading: false
                        }
                      });
                      this.dispatch({
                        type: "position/changePositionDetailSelectedCandidate",
                        payload: {//选中的人选
                          selectedRowKeys: [],//选中的人选的信息
                          selectedCandidateUid: '',//查看的人选的id
                          selectedCandidate: {},//查看的人选详情
                        }
                      });
                    }
                  }
                })
              } else {
                res.result && res.result.forEach(item => {
                  if (item.uid === positionDetailPStep.uid) {
                    this.dispatch({
                      type: "position/onChangePositionDetailPStep",
                      payload: {...item, id: item.uid, key: item.number, type: item.uid}
                    });
                  }
                })
              }

              // }
            }
          }
        });
      },
      //获取流程节点对应候选人列表
      queryCandidatesByStatus(data, state){
        getCandidatesByStatus(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/changeCandidatesByStatusList",
              payload: res.result
            });
            if (state) {
              if (res.result.records && res.result.records[0]) {
                this.dispatch({
                  type: "position/changePositionDetailSelectedCandidate",
                  payload: {//选中的人选
                    selectedRowKeys: [],//选中的人选的信息
                    selectedCandidateUid: res.result.records[0].candidateId,//查看的人选的id
                    selectedCandidate: res.result.records[0],//查看的人选详情
                  }
                });
                let positionCandidateActivityCondition = {
                  candidateIds: [res.result.records[0].candidateId], //选择的候选人id ,
                  currentActivity: 1,//是否是当前活动(1-我的活动,2-全部活动) ,
                  positionId: data.positionUid,// 职位id ,
                  pageIndex: 1,// 当前页码,从1开始 ,
                  pageLimit: 10,// 返回大小 ,
                };
                this.dispatch({
                  type: "position/changeCandidatesActivities",
                  payload: {loading: true}
                });
                getCandidatesActivities(positionCandidateActivityCondition).then(response1 => {
                  if (response1.code === 200) {
                    this.dispatch({
                      type: "position/changeCandidatesActivities",
                      payload: response1.result ? {...response1.result, loading: false} : {
                        current: 0,
                        pages: 0,
                        size: 10,
                        total: 0,
                        records: [],
                        loading: false
                      }
                    });
                  }
                });
              } else {
                this.dispatch({
                  type: "position/changeCandidatesActivities",
                  payload: {
                    current: 0,
                    pages: 0,
                    size: 10,
                    total: 0,
                    records: [],
                    loading: false
                  }
                });
                this.dispatch({
                  type: "position/changePositionDetailSelectedCandidate",
                  payload: {//选中的人选
                    selectedRowKeys: [],//选中的人选的信息
                    selectedCandidateUid: '',//查看的人选的id
                    selectedCandidate: {},//查看的人选详情
                  }
                });
              }
            }
          }
        })
      },
      //获取候选人活动列表
      queryCandidatesActivities(data, type){
        if (type === 1) {
          this.dispatch({
            type: "position/changeCandidateInfoActivities",
            payload: {loading: true}
          });
        } else {
          this.dispatch({
            type: "position/changeCandidatesActivities",
            payload: {loading: true}
          });
        }
        getCandidatesActivities(data).then(res => {
          if (res && res.code === 200) {
            let info = (res.result ? {...res.result, loading: false} : {
              current: 0,
              pages: 0,
              size: 10,
              total: 0,
              records: [],
              loading: false
            });
            if (type === 1) {
              this.dispatch({
                type: "position/changeCandidateInfoActivities",
                payload: info
              });
            } else {
              this.dispatch({
                type: "position/changeCandidatesActivities",
                payload: info
              });
            }
          }
        })
      },
      //获取流程节点各个表单的初始化数据
      async queryCandidateForm(data){
        let resultType = {};
        await getCandidateForm(data).then(res => {
          resultType = res;
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/changeCandidateForm",
              payload: res.result
            });
          }
        });
        return resultType;
      },
      //保存各个流程节点提交的表单
      async saveFlowNodesInfo(data){
        let saveResult;
        await saveFlowNodes(data).then(res => {
          saveResult = res;
        });
        return saveResult;
      },
      //保存各个流程节点提交的表单
      async updateFlowNodesInfo(data){
        let saveResult;
        await updateFlowNode(data).then(res => {
          saveResult = res;
        });
        return saveResult;
      },
      //根据职位查询招聘顾问
      queryPositionAdvisers(data){
        getPositionAdvisers(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "position/changePositionAdvisers",
              payload: res.result || []
            });
          }
        })
      },
      //修改职位状态 updatePositionStatus,
      async modifyPositionStatus(data){
        let resultData = {};
        await updatePositionStatus(data).then(res => {
          resultData = res;
        });
        return resultData;
      },
      //修改职位组织
      async modifyPositionGroup(data){
        let resultData = {};
        await updatePositionGroup(data).then(res => {
          resultData = res;
        });
        return resultData;
      },
      //当前职位职位主管所有交接人
      queryCounterpartListOfPositionId(data){
        getCounterpartListOfPositionId(data).then(res => {
          if (res && res.code === 200) {
            let arr = [];
            if (res.result && res.result.length > 0) {
              res.result && res.result.forEach(item => {
                arr.push(item.uid);
              })
            }
            this.dispatch({
              type: `position/changeSelectedCounterpart`,
              payload: {
                selectedCounterpartList: res.result || [],//列表
                selectedCounterpartListId: [...arr] || [],//列表ID
                initSelectedCounterpartListId: [...arr] || [],//初始列表ID
              }
            });
            this.dispatch({
              type: "position/searchCounterparts",
              payload: {records: res.result || []}
            });
          }
        });
      },
      //  单个职位批量分配交接人
      async modifyAssignCounterpartBatch(data){
        let resultData = {};
        await updateAssignCounterpartBatch(data).then(res => {
          resultData = res;
        });
        return resultData;
      },
      //  单个职位批量分配交接人
      async doBatchAddCounterpart(data){
        let resultData = {};
        await batchAddCounterpart(data).then(res => {
          resultData = res;
        });
        return resultData;
      },
      //  根据职位和对接人查询招聘顾问
      async searchPositionAdvisersOfCounterpart(data){
        let resultData = {};
        await searchPositionAdvisers(data).then(res => {
          resultData = res;
          // if (res.code === 200) {
          //   this.dispatch({
          //     type: "position/changePositionAdvisersOfCounterpart",
          //     payload: res.result || []
          //   });
          // }
        });
        return resultData;
      },

      //  根据职位和招聘顾问查询候选人
      async queryCandidatesByAdviserId(data){
        let resultData = {};
        await searchCandidatesByAdviserId(data).then(res => {
          resultData = res;
          // if (res.code === 200) {
          //   this.dispatch({
          //     type: "position/changeCandidatesByAdviserIdList",
          //     payload: res.result || []
          //   });
          // }
        });
        return resultData;
      },
      //当前职位我是交接人所有的招聘顾问
      queryAdviserListOfPositionId(data){
        getAdviserListOfPositionId(data).then(res => {
          if (res && res.code === 200) {
            let arr = [];
            if (res.result && res.result.length > 0) {
              res.result && res.result.forEach(item => {
                arr.push(item.uid);
              })
            }
            this.dispatch({
              type: `position/changeSelectedCounterpart`,
              payload: {
                selectedCounterpartList: [...res.result] || [],//列表
                selectedCounterpartListId: [...arr] || [],//列表ID
                initSelectedCounterpartListId: [...arr] || [],//初始列表ID
              }
            });
            this.dispatch({
              type: `position/changeSelectedCounterpartInfo`,
              payload: {
                counterpartUid: [...arr] || [],
                counterparts: [...res.result] || [],
              }
            });
            this.dispatch({
              type: "position/searchCounterparts",
              payload: {records: res.result || []}
            });
          }
        });
      },
      //分配招聘顾问
      async adviserBatch(data, isMore){
        let resultData = {};
        if (isMore) {
          await multiPositionAssignAdviserBatch(data).then(res => {
            resultData = res;
          });
        } else {
          await assignAdviserBatch(data).then(res => {
            resultData = res;
          });
        }
        return resultData;
      },

      //移交交付顾问或候选人
      async transferSave(data, type){
        let resultData = {};
        if (type === 3) {
          await transferCandidates(data).then(res => {
            resultData = res;
          });
        } else if (type === 4 || type === 5) {
          await transferAdviser(data).then(res => {
            resultData = res;
          });
        }
        return resultData;
      },
    };

    reducers = {
      //职位高级搜索弹框
      showPosSearchModel(state, {payload: pathname}) {
        return {
          ...state,
          posSearchModel: pathname
        };
      },

      //可匹配人选搜索条件弹框
      showPosCanSearchModel(state, {payload: pathname}) {
        return {
          ...state,
          posCanSearchModel: pathname
        };
      },

      //新建职位弹框
      showNewPositionModel(state, {payload: pathname}) {
        return {
          ...state,
          newPositionModel: {...state.newPositionModel, ...pathname}
        };
      },

      //编辑职位弹框
      showEditPositionModel(state, {payload: pathname}) {
        return {
          ...state,
          editPositionModel: {...state.editPositionModel, ...pathname}
        };
      },

      //职位流程抽屉
      showPositionDetailDrawer(state, {payload: pathname}) {
        return {
          ...state,
          positionDetailDrawer: pathname
        };
      },

      //职位信息抽屉
      showPositionInfoDrawer(state, {payload: pathname}) {
        return {
          ...state,
          positionInfoDrawer: pathname
        };
      },

      //职位流程操作弹框
      showMakePositionModel(state, {payload: pathname}) {
        return {
          ...state,
          makePositionModel: {...state.makePositionModel, ...pathname}
        };
      },

      //职位流程中选择职位状态中人选分类
      onChangePositionDetailPStepState(state, {payload: pathname}) {
        return {
          ...state,
          positionDetailPStepState: pathname
        };
      },

      //职位流程中选择职位状态
      onChangePositionDetailPState(state, {payload: pathname}) {
        return {
          ...state,
          positionDetailPState: pathname.type
        };
      },

      //职位流程中选择职位流程
      onChangePositionDetailPStep(state, {payload: pathname}) {
        return {
          ...state,
          // positionDetailPStep: pathname.type
          positionDetailPStep: pathname
        };
      },

      //获取自定义模板数据
      // onChangePositionTempFields(state, {payload: pathname}) {
      //   return {
      //     ...state,
      //     positionTempFields: {...pathname}
      //   };
      // },

      //职位列表查询的数据
      searchPositionList(state, {payload: pathname}) {
        return {
          ...state,
          positionListData: pathname
        };
      },

      //查看职位信息
      searchPositionInfo(state, {payload: pathname}) {
        const {isModify, ...data} = pathname;
        if (isModify) {
          return {
            ...state,
            positionInfoData: {...data, isChange: !state.positionInfoData.isChange}
          };
        } else {
          return {
            ...state,
            positionInfoData: {...data}
          };
        }
      },
      //获取职位详情额外字段
      searchPositionOtherFields(state, {payload: pathname}) {
        const {isModify, ...data} = pathname;
        if (isModify) {
          return {
            ...state,
            positionOtherInfoData: {...data, isChange: !state.positionOtherInfoData.isChange}
          };
        } else {
          return {
            ...state,
            positionOtherInfoData: {...data}
          };
        }
      },

      //查询客户信息
      searchCustomerList(state, {payload: pathname}) {
        return {
          ...state,
          customerListInfo: {...state.customerListInfo, ...pathname}
        };
      },
      //查询客户联系人信息
      searchContactList(state, {payload: pathname}) {
        return {
          ...state,
          contactListInfo: {...state.contactListInfo, ...pathname}
        };
      },

      //获取所有账号
      searchPositionAccounts(state, {payload: pathname}) {
        return {
          ...state,
          positionAccounts: {...state.positionAccounts, ...pathname}
        };
      },

      //交付对接人
      searchCounterparts(state, {payload: pathname}) {
        return {
          ...state,
          positionCounterparts: {...state.positionCounterparts, ...pathname}
        };
      },
      //onsite人员
      searchOnSites(state, {payload: pathname}) {
        return {
          ...state,
          onSites: {...state.onSites, ...pathname}
        };
      },

      //BD人员
      searchBDStaff(state, {payload: pathname}) {
        return {
          ...state,
          BDStaffs: {...state.BDStaffs, ...pathname}
        };
      },
      //email收件人
      searchOtherAccountsEmail(state, {payload: pathname}) {
        return {
          ...state,
          otherAccountsEmail: {...state.BDStaffs, ...pathname}
        };
      },

      //查询某个人的汇报线
      searchGroupsByAccount(state, {payload: pathname}) {
        return {
          ...state,
          groupsByAccount: pathname
        };
      },

      //获取职位流程列表
      searchPositionFlows(state, {payload: pathname}) {
        return {
          ...state,
          positionFlows: pathname
        };
      },

      //查找单个职位流程
      searchPositionFlowDetail(state, {payload: pathname}) {
        return {
          ...state,
          positionFlowDetail: pathname
        };
      },

      //查找候选人报告模板列表
      searchReportTemplatesList(state, {payload: pathname}) {
        return {
          ...state,
          reportTemplates: pathname
        };
      },

      //团队树形结构
      searchDeptTreeList(state, {payload: pathname}) {
        return {
          ...state,
          deptTreeLists: pathname
        };
      },

      //根据团队id查找当前团队及子团队的所有员工
      changeStaffByDeptList(state, {payload: pathname}) {
        return {
          ...state,
          staffByDeptList: pathname
        };
      },

      //获取流程职位状态数量
      changePositionFlowProgressCounter(state, {payload: pathname}) {
        return {
          ...state,
          positionFlowProgressCounter: pathname
        };
      },
      //获取流程职位进展状态数量
      changePositionStateCounter(state, {payload: pathname}) {
        return {
          ...state,
          positionStateCounter: pathname
        };
      },
      //获取流程节点对应候选人列表
      changeCandidatesByStatusList(state, {payload: pathname}) {
        return {
          ...state,
          candidatesByStatusList: pathname
        };
      },
      //选择候选人状态
      changePositionDetailOwnerState(state, {payload: pathname}) {
        return {
          ...state,
          positionDetailOwnerState: pathname.state
        };
      },

      //获取流程节点对应候选人活动列表
      changeCandidatesActivities(state, {payload: pathname}) {
        return {
          ...state,
          candidatesActivitiesList: {...state.candidatesActivitiesList, ...pathname}
        };
      },
      //候选人详情候选人活动列表
      changeCandidateInfoActivities(state, {payload: pathname}) {
        return {
          ...state,
          candidateInfoActivitiesList: {...state.candidateInfoActivitiesList, ...pathname}
        };
      },
      //修改选中的人选
      changePositionDetailSelectedCandidate(state, {payload: pathname}) {
        return {
          ...state,
          positionDetailSelectedCandidate: {...state.positionDetailSelectedCandidate, ...pathname}
        };
      },

      //获取流程节点各个表单的初始化数据
      changeCandidateForm(state, {payload: pathname}) {
        return {
          ...state,
          candidateFormInfo: pathname
        };
      },
      //根据职位查询招聘顾问
      changePositionAdvisers(state, {payload: pathname}) {
        return {
          ...state,
          positionAdvisersList: pathname
        };
      },
      //修改选中的bd人员
      changeSelectedBdStaff(state, {payload: pathname}) {
        return {
          ...state,
          selectedBdStaff: pathname
        };
      },
      //修改选中的客户
      changeSelectedCustomer(state, {payload: pathname}) {
        return {
          ...state,
          selectedCustomer: pathname
        };
      },
      //修改选中的客户联系人
      changeSelectedContact(state, {payload: pathname}) {
        return {
          ...state,
          selectedContact: pathname
        };
      },
      //修改选中的交付对接人
      changeSelectedCounterpart(state, {payload: pathname}) {
        return {
          ...state,
          selectedCounterpart: {...state.selectedCounterpart, ...pathname}
        };
      },
      //根据职位和对接人查询招聘顾问
      // changePositionAdvisersOfCounterpart(state, {payload: pathname}) {
      //   return {
      //     ...state,
      //     positionAdvisersOfCounterpart: pathname
      //   };
      // },
      //改变选中的交付对接人
      changeSelectedCounterpartInfo(state, {payload: pathname}) {
        // console.log(2134567, pathname)
        return {
          ...state,
          selectedCounterpartInfo: pathname
        };
      },
      //改变移交弹框状态（交付顾问和交付对接人）
      changeTransferDialogModel(state, {payload: pathname}) {
        return {
          ...state,
          transferDialogModel: {...state.transferDialogModel, ...pathname}
        };
      },
      // //改变移交弹框状态（交付顾问和交付对接人）
      // changeCandidatesByAdviserIdList(state, {payload: pathname}) {
      //   return {
      //     ...state,
      //     candidatesByAdviserIdList: pathname
      //   };
      // },
      //改变职位列表加载状态（交付顾问和交付对接人）
      changePositionListLoading(state, {payload: pathname}) {
        return {
          ...state,
          positionListLoading: {...state.positionListLoading, ...pathname}
        };
      },
      //改变职位可匹配候选人列表加载状态（交付顾问和交付对接人）
      changePositionMatchCandidateLoading(state, {payload: pathname}) {
        return {
          ...state,
          positionMatchCandidateLoading: {...state.positionMatchCandidateLoading, ...pathname}
        };
      },

      //开票loading
      changeInvoiceLoading(state, {payload: pathname}) {
        return {
          ...state,
          invoiceLoading: {...state.invoiceLoading, ...pathname}
        };
      },
    };
  }
);
