/**
 * Created by CI11840 on 2019/12/31.
 */
/* eslint-disable */

import React, {PureComponent} from 'react';
import {Form, Icon, Input, Button} from 'antd';
import {history} from 'root';
import {connect} from 'react-redux';
import userModel from 'store/reducers/user';
// import {getCookie} from '../../utils/cookie';
import {showPageTitle} from '../../utils/util'
import {updatePassword} from '../../services/api1'
import './login.scss';
const FormItem = Form.Item;

@connect(({user}) => ({...user}), {...userModel.actions})
class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false // 登录加载loading
    };
  }

  componentDidMount() {
    showPageTitle(this.props.history.location.pathname);
  }

  handleSubmit = e => {
    e.preventDefault();
    const {userInfo, setUser} = this.props;
    this.setState({loading: true});
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          password: values.password,
          accountUid: userInfo.uid,
          oldPassword: values.oldPassword
        };
        updatePassword(data).then(res => {
          if (res.code === 200) {
            const info = {...userInfo, loginType: 'username'};
            setUser(info);
            localStorage.setItem('currentUser', JSON.stringify(info));
            const href = localStorage.getItem('recordUrl');
            if (href) {
              window.location.href = href;
              localStorage.removeItem('recordUrl');
            } else {
              let isPlatAdmin = (userInfo.roleType === 'platadmin');
              if (isPlatAdmin) {
                history.replace('/operation/:type');
              } else {
                history.replace('/');
              }
            }
          }
          this.setState({loading: false});
        });
      } else {
        this.setState({loading: false});
      }
    });
  };
  validatorAgainPassword = (rule, value, callback) => {
    const {getFieldValue} = this.props.form;
    const getPassword = getFieldValue('password');
    if (!!value) {
      if (getPassword === value) {
        callback();
      } else {
        callback('请再次确认输入的新密码');
      }
    } else {
      callback('请再次输入新密码');
    }
  };
  validatorPassword = (rule, value, callback) => {
    const {getFieldValue} = this.props.form;
    const oldPassword = getFieldValue('oldPassword');
    if (!!value) {
      const reg = /^[a-zA-Z0-9.@_-]{6,30}$/;
      const flag = reg.test(value);
      if (flag) {
        if (value === oldPassword) {
          callback('密码不能和旧密码一致，请重新设置');
        } else {
          const values = ['123456', 'password'];
          let getValue = value.toLocaleLowerCase();
          if (values.includes(getValue)) {
            callback('密码过于简单，请重新设置');
          } else {
            callback();
          }
        }
      } else {
        callback('6-30位（请不要使用特殊字符）')
      }
    } else {
      callback('请输入新密码');
    }
  };

  render() {
    const {form: {getFieldDecorator}} = this.props;
    const {loading} = this.state;

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit} className="login-form" autoComplete="off">
          <h1>用户修改密码</h1>
          <FormItem>
            {getFieldDecorator('oldPassword', {
              validateTrigger: 'onBlur',
              rules: [{required: true, type: 'string', message: '请输入旧密码'}]
            })(
              <Input
                autoComplete="off"
                prefix={
                  <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>
                }
                type="password"
                placeholder="请输入旧密码"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              validateTrigger: 'onBlur',
              rules: [{validator: this.validatorPassword}]
            })(
              <Input
                autoComplete="off"
                prefix={
                  <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>
                }
                type="password"
                placeholder="请输入新密码"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('againPassword', {
              validateTrigger: 'onBlur',
              rules: [{validator: this.validatorAgainPassword}]
            })(
              <Input
                autoComplete="off"
                prefix={
                  <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>
                }
                type="password"
                placeholder="请再次输入新密码"
              />
            )}
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              className="login-form-button">
              {loading ? (<Icon type="loading"/>) : ''}
              <span>修改密码</span>
            </Button>
          </FormItem>
        </Form>
        <p className='login-copyright' align="center">Copyright © 2017-2021 科锐数字科技（苏州）有限公司</p>
      </React.Fragment>
    );
  }
}
export default Form.create()(Login);
// export default connect(
//   ({user}) => ({...user}),
//   {...userModel.actions}
// )(LoginForm);
