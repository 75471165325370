/**
 * Created by common on 2017/8/10.
 */
import {getCookie} from './cookie'
import moment from "moment";

/**
 * 解析url参数
 * @param {String} str url
 * @returns {Array} 参数列表
 */
function parseQueryString(str) {
  var reg = /(([^?&=]+)(?:=([^?&=]*))*)/g;
  var result = {};
  var match;
  var key;
  var value;
  while ((match = reg.exec(str))) {
    key = match[2];
    value = match[3] || "";
    result[key] = decodeURIComponent(value);
  }
  return result;
}

/**
 * 参数对象转url参数
 * @param param
 * @param key
 * @param encode
 * @returns {string}
 */
function urlEncode(param, key, encode) {
  if (param == null) return '';
  let paramStr = '';
  let t = typeof (param);
  if (t === 'string' || t === 'number' || t === 'boolean') {
    paramStr += '&' + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
  } else {
    let formBody = [];
    for (let property in param) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    paramStr = formBody.join("&");
  }
  return paramStr;
}

// 给url增加时间戳
function timestamp(url) {
  const getTimestamp = new Date().getTime();
  if (url.indexOf("?") > -1) {
    url = url + "&timestamp=" + getTimestamp
  } else {
    url = url + "?timestamp=" + getTimestamp
  }
  return url;
}

/**
 * 取任意级别city选项
 * @param {String} childFieldName 城市子数组字段名
 */
function getSomeCitys(childFieldName) {
  /**
   * @param {Array} cityOptions 城市选项
   * @param {Number} limit 取到第几层
   * @param {Number} deep 当前层数
   */
  return function getSomeCitysInner(cityOptions, limit, deep = 0) {
    cityOptions = cityOptions || [];
    deep++;
    const converted = cityOptions.map(item => {
      const res = {};
      for (const key of Object.keys(item)) {
        if (key !== childFieldName) {
          res[key] = item[key];
        }
      }
      if (deep < limit) {
        res[childFieldName] = getSomeCitysInner(
          item[childFieldName],
          limit,
          deep
        );
      }
      return res;
    });
    return converted;
  };
}

/**
 * 扁平化层级选项
 * @param {Array} data 多层数据
 * @param {String} childFieldName 子数组字段名
 */
function flatHierarchyOptions(data, childFieldName) {
  const flatedData = [];

  function hierarchy(data) {
    data = data || [];
    for (const item of data) {
      flatedData.push(item);
      const childItems = item[childFieldName];
      if (childItems) {
        delete item[childFieldName];
        hierarchy(childItems);
      }
    }
  }

  data = JSON.parse(JSON.stringify(data));
  hierarchy(data, childFieldName);
  return flatedData;
}
//判断二级路由
function selectType(count, type) {
  let pre = getPre(2, 4);
  let backType = pre + '1';
  for (let i = 1; i <= count; i++) {
    if (pre + i === type) {
      backType = type;
    }
  }
  return backType;
}
//截取获得二级路由前缀
function getPre(a, b) {
  return window.location.hash.substring(a, b) + '-';
}

//获取登录人的ID
function getAccountId() {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  let accountUid = '';
  if (!!currentUser) {
    if (currentUser.roleType === 'superadmin') {
      if (currentUser.bindAccountUid) {
        accountUid = currentUser.bindAccountUid;
      } else {
        window.location.href = '/#/user/login';
      }
    } else {
      accountUid = currentUser.uid;
    }
  }
  return accountUid;
}
//获取登录人的name
function getAccountInfo(type) {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  let data = '';
  if (!!currentUser) {
    if (type === 'name') {
      if (currentUser.roleType === 'superadmin') {
        if (currentUser.bindAccount) {
          // data = `${currentUser.bindAccount.englishName}(${currentUser.bindAccount.name})`;
          data = getZhName(currentUser.bindAccount.englishName,currentUser.bindAccount.name);

        } else {
          window.location.href = '/#/user/login';
        }
      } else {
        // data = `${currentUser.englishName}(${currentUser.name})`;
        data = getZhName(currentUser.englishName,currentUser.name);
      }
    } else if (type === 'groupUid') {
      if (currentUser.roleType === 'superadmin') {
        if (currentUser.bindAccount) {
          data = currentUser.bindAccount.groupUid;
        } else {
          window.location.href = '/#/user/login';
        }
      } else {
        data = currentUser.groupUid;
      }
    } else if (type === 'groupName') {
      if (currentUser.roleType === 'superadmin') {
        if (currentUser.bindAccount) {
          data = currentUser.bindAccount.groupName;
        } else {
          window.location.href = '/#/user/login';
        }
      } else {
        data = currentUser.groupName;
      }
    } else if (type === 'userName') {
      if (currentUser.roleType === 'superadmin') {
        if (currentUser.bindAccount) {
          data = currentUser.bindAccount.userName;
        } else {
          window.location.href = '/#/user/login';
        }
      } else {
        data = currentUser.userName;
      }
    }
  }
  return data;
}
//做单活动的基本信息及key值
function getActivtyKeys() {
  const dataKeys = {
    'FLOW_RESULT:002': {
      title: '添加联系',
      titleTime: null,
      footerTime: null,
      footerName: '',
      isCompleted: 0,//0未完成1完成
      isEdit: 0,//0不可编辑1可编辑
      id: '',//活动id
      icon: 'icon-phone',
      editType: 1,
      data: {},
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'communMethod', value: '联系方式', type: 'string'},
        {id: 3, key: 'candidateFeedBack', value: '求职状态', type: 'string'},
        {id: 4, key: 'positionPurpose', value: '求职动机', type: 'string'},
        {id: 5, key: 'positionDirection', value: '求职方向', type: 'string'},
        {id: 6, key: 'currentSituation', value: '当前情况', type: 'string'},
        {id: 7, key: 'questions', value: '必问问题', type: 'object'},
        {id: 8, key: 'nextFollowDate', value: '下次跟进时间', type: 'time'},
      ],
      fun: [{id: 1, name: '推荐初审', type: 3, show: 1}]
    },
    'FLOW_RESULT:003': {
      title: '待初审',
      titleTime: null,
      footerTime: null,
      footerName: '',
      isCompleted: 0,//0未完成1完成
      isEdit: 0,//0不可编辑1可编辑
      icon: 'icon-audit',
      editType: 3,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'fileRefs', value: '候选人报告', type: 'files', fileType: 1},
        {id: 3, key: 'questions', value: '必问问题回答', type: 'object'},
        {id: 4, key: 'recommandReason', value: '推荐理由', type: 'string'},
      ],
      fun: [{id: 1, name: '初审', type: 10, show: 1}]
    },
    'FLOW_RESULT:003:001': {
      title: '初审未通过',
      icon: 'icon-audit',
      editType: 10,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'fileRefs', value: '候选人报告', type: 'files', fileType: 1},
        {id: 3, key: 'questions', value: '必问问题回答', type: 'object'},
        {id: 4, key: 'recommandReason', value: '推荐理由', type: 'string'},
        {id: 5, key: 'communRecord', value: '备注', type: 'string'},
      ]
    },
    'FLOW_RESULT:005': {
      title: '待推荐',
      titleTime: null,
      footerTime: null,
      footerName: '',
      isCompleted: 0,//0未完成1完成
      isEdit: 0,//0不可编辑1可编辑
      icon: 'icon-fasong',
      editType: 10,
      data: {},
      list: [
        {id: 1, key: 'advisorIds', value: '推荐顾问', type: 'string'},
        {id: 2, key: 'fileRefs', value: '候选人报告', type: 'files', fileType: 1},
        {id: 3, key: 'questions', value: '必问问题回答', type: 'object'},
        {id: 4, key: 'recommandReason', value: '推荐理由', type: 'string'},
        {id: 5, key: 'communRecord', value: '备注', type: 'string'},
      ],
      fun: [{id: 1, name: '发送推荐', type: 4, show: 1}]
    },
    'FLOW_RESULT:006': {
      title: '待回复',
      titleTime: null,
      footerTime: null,
      footerName: '',
      isCompleted: 0,//0未完成1完成
      isEdit: 0,//0不可编辑1可编辑
      icon: 'icon-message',
      editType: 4,
      data: {},
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'receivers', value: '收件人', type: 'array'},
        {id: 3, key: 'copySenders', value: '抄送人', type: 'array'},
        {id: 4, key: 'secretSenders', value: '密送人', type: 'array'},
        {id: 5, key: 'mailTitle', value: '邮件标题', type: 'string'},
        {id: 6, key: 'fileRefs', value: '候选人报告', type: 'files', fileType: 1},
        {id: 7, key: 'mailBody', value: '邮件内容', type: 'string'},
      ],
      fun: [{id: 1, name: '客户筛选反馈', type: 11, show: 1}]
    },
    'FLOW_RESULT:007': {
      title: '等待面试安排',
      icon: 'icon-team',
      editType: 11,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'feedback', value: '客户筛选反馈', type: 'string'},
        {id: 3, key: 'communRecord', value: '备注', type: 'string'},
      ],
      fun: [{id: 1, name: '安排面试', type: 5, show: 1}]
    },
    'FLOW_RESULT:006:001': {
      title: '客户筛选未通过(待回复)',
      icon: 'icon-shaixuan',
      editType: 11,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'feedback', value: '反馈', type: 'string'},
        {id: 3, key: 'communRecord', value: '备注', type: 'string'},
      ]
    },
    'FLOW_RESULT:009:001': {
      title: '客户筛选未通过(客户待定)',
      icon: 'icon-shaixuan',
      editType: 11,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'feedback', value: '客户筛选反馈', type: 'string'},
        {id: 3, key: 'communRecord', value: '备注', type: 'string'},
      ]
    },
    'FLOW_RESULT:009': {
      title: '客户待定',
      icon: 'icon-shaixuan',
      editType: 11,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'feedback', value: '客户筛选反馈', type: 'string'},
        {id: 3, key: 'communRecord', value: '备注', type: 'string'},
      ],
      fun: [{id: 1, name: '客户筛选反馈', type: 11, show: 1}]
    },
    'FLOW_RESULT:010': {
      title: '面试中',
      icon: 'icon-team',
      editType: 5,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'interviewDate', value: '面试时间', type: 'time'},
        {id: 3, key: 'interviewerType', value: '面试方式', type: 'string'},
        {id: 4, key: 'interviewLocation', value: '面试地点', type: 'string'},
        {id: 5, key: 'interviewerId', value: '面试官', type: 'string'},
        {id: 6, key: 'commuRecord', value: '备注', type: 'string'},
      ],
      fun: [
        {id: 1, name: '客户面试反馈', type: 12, show: 1},
        {id: 2, name: '候选人面试反馈', type: 18, show: 2},
      ]
    },
    'FLOW_RESULT:011': {
      title: '面试通过',
      icon: 'icon-team',
      editType: 12,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'interviewDate', value: '面试反馈时间', type: 'time'},
        {id: 3, key: 'attend', value: '出席情况', type: 'string'},
        {id: 4, key: 'interviewResult', value: '面试结果', type: 'string'},
        {id: 5, key: 'final', value: '是否终轮', type: 'string'},
        {id: 6, key: 'communRecord', value: '客户面试反馈', type: 'string'},
      ],
      fun: [
        {id: 1, name: '创建offer', type: 6, show: 1},
        {id: 2, name: '安排面试', type: 5, show: 1},
      ]
    },
    'FLOW_RESULT:010:001': {
      title: '面试未通过',
      icon: 'icon-team',
      editType: 12,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'interviewDate', value: '面试时间', type: 'time'},
        {id: 3, key: 'attend', value: '出席情况', type: 'string'},
        {id: 4, key: 'interviewResult', value: '面试结果', type: 'string'},
        {id: 5, key: 'final', value: '是否终轮', type: 'string'},
        {id: 6, key: 'communRecord', value: '客户面试反馈', type: 'string'},
      ]
    },
    'FLOW_RESULT:010:002': {
      title: '面试未出席',
      icon: 'icon-team',
      editType: 12,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'interviewDate', value: '面试时间', type: 'time'},
        {id: 3, key: 'attend', value: '出席情况', type: 'string'},
        {id: 4, key: 'communRecord', value: '客户面试反馈', type: 'string'},
      ]
    },
    'FLOW_RESULT:014': {
      title: '候选人面试反馈',
      icon: 'icon-phone',
      editType: 18,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'followOption', value: '意向跟进', type: 'string'},
        {id: 3, key: 'feedBack', value: '备注', type: 'string'},
      ]
    },
    'FLOW_RESULT:010:003': {
      title: '候选人面试反馈 | 出局',
      icon: 'icon-phone',
      editType: 18,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'followOption', value: '意向跟进', type: 'string'},
        {id: 3, key: 'feedBack', value: '备注', type: 'string'},
      ]
    },
    'FLOW_RESULT:016': {
      title: '背景调查',
      icon: 'icon-edit-square',
      editType: 2,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'fileTemplateRefs', value: '背调模板', type: 'file'},
        {id: 3, key: 'completeDate', value: '背调时间', type: 'time'},
        {id: 4, key: 'visitedUser', value: '被访问人姓名', type: 'string'},
        {id: 5, key: 'visitedCompany', value: '被访问人公司', type: 'string'},
        {id: 6, key: 'visitedPosition', value: '被访问人职位', type: 'string'},
        {id: 7, key: 'visitedTel', value: '被访问人电话', type: 'string'},
        {id: 8, key: 'relationShip', value: '与候选人关系', type: 'string'},
        {id: 9, key: 'researchRecord', value: '调查记录', type: 'string'},
        {id: 10, key: 'fileResultRefs', value: '背调报告', type: 'files'},
      ],
      fun: [
        {id: 1, name: '上传结果', type: 2, show: 1},
        {id: 2, name: '取消背调', type: 19, show: 2},
      ]
    },
    'FLOW_RESULT:017': {
      title: '等待签署',
      icon: 'icon-idcard',
      editType: 6,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'actualSalary', value: '实际年薪', unit: 1, type: 'money'},
        {id: 3, key: 'hasServiceExpense', value: '是否有服务费', type: 'string'},
        {id: 4, key: 'serviceExpense', value: '服务费', unit: '', type: 'money'},
        {id: 5, key: 'taxExpense', value: '税费', unit: '', type: 'money'},
        {id: 6, key: 'totalAmount', value: '总金额', unit: '', type: 'money'},
        {id: 7, key: 'relatedDivision', value: '是否存在分成', type: 'string'},
        {id: 8, key: 'amountDivideConfigs', value: '分成人员', type: 'string'},
        {id: 9, key: 'communRecord', value: '备注', type: 'string'},
      ],
      fun: [
        {id: 1, name: '接受offer', type: 13, show: 1},
        {id: 2, name: '拒绝offer', type: 14, show: 1},
        {id: 3, name: '客户取消', type: 15, show: 1},
        // {id: 4, name: '开票', type: '', show: 3},
      ]
    },
    'FLOW_RESULT:018': {
      title: '取消背调',
      icon: 'icon-edit-square',
      editType: 19,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'fileTemplateRefs', value: '背调模板', type: 'file'},
        {id: 3, key: 'completeDate', value: '背调时间', type: 'time'},
        {id: 4, key: 'visitedUser', value: '被访问人姓名', type: 'string'},
        {id: 5, key: 'visitedCompany', value: '被访问人公司', type: 'string'},
        {id: 6, key: 'visitedPosition', value: '被访问人职位', type: 'string'},
        {id: 7, key: 'visitedTel', value: '被访问人电话', type: 'string'},
        {id: 8, key: 'relationShip', value: '与候选人关系', type: 'string'},
        {id: 9, key: 'researchRecord', value: '调查记录', type: 'string'},
        {id: 10, key: 'fileResultRefs', value: '背调报告', type: 'files'},
        {id: 11, key: 'cancelReason', value: '取消原因', type: 'string'},
      ]
    },
    'FLOW_RESULT:019': {
      title: '入职到岗',
      icon: 'icon-gongwenbao',
      editType: 13,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'expectedSignDate', value: '预期签署日期时间', type: 'date'},
        {id: 3, key: 'actualSignDate', value: '实际签署日期时间', type: 'date'},
        {id: 4, key: 'arrivalDate', value: '预计到岗日期', type: 'date'},
        {id: 6, key: 'interval', value: '设置提醒', type: 'string'},
        {id: 5, key: 'commuRecord', value: '备注', type: 'string'},
      ],
      fun: [
        {id: 1, name: '确认入职', type: 7, show: 1},
        {id: 2, name: '候选人放弃', type: 16, show: 1},
        {id: 3, name: '客户取消', type: 20, show: 1},
      ]
    },
    'FLOW_RESULT:017:001': {
      title: '拒绝offer',
      icon: 'icon-idcard',
      editType: 14,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'refuseDate', value: '拒绝offer日期时间', type: 'time'},
        {id: 3, key: 'refuseReason', value: '拒绝原因', type: 'string'},
      ]
    },
    'FLOW_RESULT:017:002': {
      title: '取消offer',
      icon: 'icon-idcard',
      editType: 15,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'cancelDate', value: '取消offer日期时间', type: 'time'},
        {id: 3, key: 'cancelReason', value: '取消原因', type: 'string'},
      ]
    },
    'FLOW_RESULT:017:003': {
      title: '客户取消(等待签署)',
      icon: 'icon-gongwenbao',
      editType: 15,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'cancelDate', value: '取消时间', type: 'time'},
        {id: 3, key: 'cancelReason', value: '取消原因', type: 'string'},
      ],
    },
    'FLOW_RESULT:023': {
      title: '保证期中',
      icon: 'icon-insurance',
      editType: 7,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'entryDate', value: '入职时间', type: 'date'},
        {id: 3, key: 'guaranteeDate', value: '有无保证期', type: 'string'},
        {id: 4, key: 'expireDate', value: '截止日', type: 'date'},
        {id: 6, key: 'interval', value: '入职跟进', type: 'string'},
        {id: 5, key: 'communRecord', value: '备注', type: 'string'},
      ],
      fun: [
        {id: 1, name: '保证期反馈', type: 8, show: 1},
        {id: 2, name: '保证期跟进', type: 17, show: 2},
        // {id: 3, name: '开票', type: '', show: 3},
      ]
    },
    'FLOW_RESULT:019:001': {
      title: '放弃入职',
      icon: 'icon-gongwenbao',
      editType: 16,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'dropDate', value: '放弃时间', type: 'time'},
        {id: 3, key: 'dropReason', value: '放弃原因', type: 'string'},
      ],
    },
    'FLOW_RESULT:019:002': {
      title: '客户取消(等待入职)',
      icon: 'icon-gongwenbao',
      editType: 20,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'cancelDate', value: '取消时间', type: 'time'},
        {id: 3, key: 'cancelReason', value: '取消原因', type: 'string'},
      ],
    },
    'FLOW_RESULT:026': {
      title: '成功过保',
      icon: 'icon-insurance',
      editType: 8,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'estimateExpireDate', value: '截止日期', type: 'date'},
        {id: 3, key: 'communRecord', value: '备注', type: 'string'},
      ],
      fun: [
        // {id: 1, name: '开票', type: '', show: 3},
      ]
    },
    'FLOW_RESULT:027': {
      title: '保证期跟进',
      icon: 'icon-phone',
      editType: 17,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'followDate', value: '日期时间', type: 'time'},
        {id: 3, key: 'tel', value: '联系方式', type: 'string'},
        {id: 4, key: 'communRecord', value: '备注', type: 'string'},
      ]
    },
    'FLOW_RESULT:023:001': {
      title: '未通过保证期',
      icon: 'icon-insurance',
      editType: 8,
      list: [
        {id: 1, key: 'advisorIds', value: '参与顾问', type: 'string'},
        {id: 2, key: 'estimateExpireDate', value: '截止日期', type: 'date'},
        {id: 3, key: 'communRecord', value: '备注', type: 'string'},
      ]
    },
    'FLOW_RESULT:029': {
      title: '出局',
      icon: 'icon-shaixuan',
      editType: 9,
      list: [
        // {id: 1, key: 'name', value: '参与顾问'},
        // {id: 2, key: 'phone', value: '截止日期'},
        // {id: 3, key: 'name', value: '沟通记录'},
      ]
    },
    // 'FLOW_RESULT:030': {
    //   title: '恢复出局状态',
    //   icon: 'icon-insurance',
    //   editType: 8,
    //   list: [
    //     {id: 1, key: 'name', value: '参与顾问'},
    //     {id: 2, key: 'phone', value: '截止日期'},
    //     {id: 3, key: 'name', value: '沟通记录'},
    //   ]
    // },
  };
  return dataKeys;
}

//显示页面标题
function showPageTitle(pathname) {
  let obj = {
    workbench: '工作台',
    customer: '客户',
    customerDetail: '客户详情',
    position: '职位',
    positionDetail: '职位详情',
    candidate: '候选人',
    candidateDetail: '候选人详情',
    reports: '报表',
    finance: '财务',
    operation: '运营中心',
    user: '登录',
    instructions: '帮助中心',
    similarPosition: '相似职位'
  };
  let key = pathname && pathname.split('/')[1];
  if (!!obj[key]) {
    document.title = obj[key];
  }
}

//显示明细列表
function showDetailList(data) {
  localStorage.setItem('detail_params', JSON.stringify(data));
  window.open(`${window.location.protocol}//${window.location.host}/#/detail`);
}

//验证日期
function validatorDate(rule, value, callback, setFieldsValue, key) {
  if (!!value) {
    const reg1 = /^\d+$/;//判断是否是纯数字（整数）;
    const reg2 = /^(19|20)\d{2}$/;//年份正则
    const reg3 = /^(19|20)\d{2}-((0?[1-9])|(1[0-2]{0,1}))$/;//年月正则
    const reg4 = /^(19|20)\d{2}-((0?[1-9])|([1-9])|(1[0-2]))-((0?[1-9])|([1-9])|([1-2][0-9])|30|31)$/;//年月日
    let val = '';
    let status = true;

    function formatDate(date) {
      if (reg2.test(date)) {
        setFieldsValue({[key]: `${date}-01-01`});
        callback();
      } else if (reg3.test(date)) {
        setFieldsValue({[key]: `${date}-01`});
        callback();
      } else if (reg4.test(date)) {
        let arr = date.split('-');
        let IYear = arr[0];
        let IMonth = arr[1];
        let IDate = arr[2];
        if (/^((0?[469])|11)$/.test(IMonth) && IDate === '31') {
          callback('格式错误，天数错误');
        } else if (parseInt(IMonth, 10) === 2) {
          if ((IYear % 4 === 0 && IYear % 100 !== 0) || (IYear % 400 === 0)) {
            if (IDate <= '29') {
              setFieldsValue({[key]: date});
              callback();
            } else {
              callback('格式错误，天数错误');
            }
          } else if (parseInt(IDate, 10) > 28) {
            callback('格式错误，天数错误');
          } else {
            setFieldsValue({[key]: date});
            callback();
          }
        } else {
          setFieldsValue({[key]: date});
          callback();
        }
      } else {
        callback('正确格式为:YYYY-MM-DD');
      }
    }

    if (reg1.test(value)) {
      let len = value.length;
      if (status) {
        switch (len) {
          case 4:
            val = value;
            break;
          case 6:
            val = `${value.substring(0, 4)}-${value.substring(4, 6)}`;
            break;
          case 8:
            val = `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}`;
            break;
          default:
            status = false;
            break;
        }
        formatDate(val);
      } else {
        callback('格式错误，正确为YYYY-MM-DD');
      }
    } else {
      let valueArr = value.match(/\d+/g);
      if (!!valueArr) {
        formatDate(valueArr.join('-'));
      } else {
        callback('格式错误，正确为YYYY-MM-DD');
      }
    }
  } else {
    if (rule.required) {
      callback('不能为空');
    } else {
      callback();
    }
  }
}

//获取按钮权限
function getButtonResources() {
  let data = JSON.parse(localStorage.getItem('buttonResources'));
  return data;
}
//金额单位列表
function moneyUnitList() {
  let list = [
    {unit: 'CNY', value: 1, name: '人民币'},
    {unit: 'USD', value: 2, name: '美元'},
    {unit: 'EUR', value: 3, name: '欧元'},
    {unit: 'GBP', value: 4, name: '英镑'},
    {unit: 'HKD', value: 5, name: '港元'},
    {unit: 'SGD', value: 6, name: '新加坡元'},
    {unit: 'TWD', value: 7, name: '新台币'},
    {unit: 'JPY', value: 8, name: '日元'},
    {unit: 'KRW', value: 9, name: '韩元'},
  ];
  return list;
}
//获取金额单位
function moneyUnitObj(unit) {
  let obj = {
    '1': 'CNY',
    '2': 'USD',
    '3': 'EUR',
    '4': 'GBP',
    '5': 'HKD',
    '6': 'SGD',
    '7': 'TWD',
    '8': 'JPY',
    '9': 'KRW'
  };
  return obj[`${unit}`]
}
//显示，金额格式化，三位加逗号
function moneyFormat(money) {
  let type = typeof money;
  if (type === 'number' && !isNaN(money)) {
    return money.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (type === 'string') {
    let n = parseFloat(money.replace(/,/g, '')).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return n === 'NaN' ? null : n;
  } else {
    return null;
  }
}
//输入框金额格式化，三位加逗号
function validatorMoney(rule, value, callback, setFieldsValue, key, min) {
  const minNum = min || 0;
  if (typeof (value) === 'number' || (typeof (value) === 'string' && !!value)) {
    let n = parseFloat(value.toString().replace(/,/g, '')).toFixed(2);
    if (n !== 'NaN' && !!n) {
      const reg1 = /^(?:[1-9]\d{0,9}|0)(?:\.\d{0,2})?$/;
      if (reg1.test(n)) {
        if (n >= minNum) {
          setFieldsValue({[key]: n.replace(/\B(?=(\d{3})+(?!\d))/g, ',')});
          callback();
        } else {
          callback(`请输入大于 ${minNum} 的数`);
        }
      } else {
        callback('最多保留两位小数，整数部分不超过10位数');
      }
    } else {
      callback('不能为空');
      setFieldsValue({[key]: ''})
    }
  } else {
    if (rule.required) {
      callback('不能为空');
    } else {
      callback();
    }
  }
}
//追踪动画
function trackingAnimation(start, end, box) {
  let p_start = document.getElementById(start);
  let p_end = document.getElementById(end);
  let c_box = document.getElementById(box);
  if (p_start && p_end && c_box) {
    let offset_s_t = getTop(p_start);
    let offset_s_l = getLeft(p_start);
    let offset_e_t = getTop(p_end);
    let offset_e_l = getLeft(p_end);
    let offset_s_w = p_start.offsetWidth;
    let offset_s_h = p_start.offsetHeight;
    let offset_e_w = p_end.offsetWidth;
    let offset_e_h = p_end.offsetHeight;
    c_box.style.cssText = `width:${offset_s_w}px;height:${offset_s_h}px;top:${offset_s_t}px;left:${offset_s_l}px;display: block;`;
    setTimeout(() => {
      c_box.style.cssText = `transition:all 0.4s;width:${offset_e_w}px;height:${offset_e_h}px;top:${offset_e_t}px;left:${offset_e_l}px;display: block;`;
      setTimeout(() => {
        c_box.style.display = 'none';
      }, 500)
    }, 20);
    //获取元素的纵坐标
    function getTop(e) {
      let offset = e.offsetTop;
      if (e.offsetParent !== null) offset += getTop(e.offsetParent);
      return offset;
    }

    //获取元素的横坐标
    function getLeft(e) {
      let offset = e.offsetLeft;
      if (e.offsetParent !== null) offset += getLeft(e.offsetParent);
      return offset;
    }

    // c_box.style.cssText = `width:${offset_s_w}px;height:${offset_s_h}px;top:${offset_s_t}px;left:${offset_s_l}px;display: block;`;
    // let timer = null;
    // let speed_t = (offset_e_t - offset_s_t) / 40;//缓冲动画的速度参数变化值 top
    // let speed_l = (offset_e_l - offset_s_l) / 40;//缓冲动画的速度参数变化值 left
    // let speed_w = (offset_e_w - offset_s_w) / 40;//缓冲动画的速度参数变化值 width
    // let speed_h = (offset_e_h - offset_s_h) / 40;//缓冲动画的速度参数变化值 height
    // clearInterval(timer);//执行当前动画同时清除之前的动画
    // timer = setInterval(() => {
    //   offset_s_w += speed_w;
    //   offset_s_h += speed_h;
    //   offset_s_t += speed_t;
    //   offset_s_l += speed_l;
    //   if (c_box.offsetWidth <= offset_e_w) {
    //     clearInterval(timer);
    //     c_box.style.cssText = `width:${offset_e_w + 8}px;height:${offset_e_h + 8}px;top:${offset_e_t - 4}px;left:${offset_e_l - 4}px;display: block;`;
    //     setTimeout(() => {
    //       c_box.style.cssText = `display:none`;
    //     }, 200);
    //   } else {
    //     c_box.style.cssText = `width:${offset_s_w + 8}px;height:${offset_s_h + 8}px;top:${offset_s_t - 4}px;left:${offset_s_l - 4}px;display: block;`;
    //   }
    // }, 10);
  }
}

//获取浏览器信息
function getExplorerInfo() {
  let explorer = window.navigator.userAgent.toLowerCase();
  if (explorer.indexOf('msie') >= 0) {//ie
    let ver = explorer.match(/msie ([\d.]+)/)[1];
    return {type: 'IE', version: ver};
  } else if (explorer.indexOf('firefox') >= 0) {//firefox
    let ver = explorer.match(/firefox\/([\d.]+)/)[1];
    return {type: 'Firefox', version: ver};
  } else if (explorer.indexOf('chrome') >= 0) {//Chrome
    let ver = explorer.match(/chrome\/([\d.]+)/)[1];
    return {type: 'Chrome', version: ver};
  } else if (explorer.indexOf('opera') >= 0) {//Opera
    let ver = explorer.match(/opera.([\d.]+)/)[1];
    return {type: 'Opera', version: ver};
  } else if (explorer.indexOf('safari') >= 0) {//Safari
    let ver = explorer.match(/version\/([\d.]+)/)[1];
    return {type: 'Safari', version: ver};
  } else {
    return {type: '未知', version: explorer};
  }
}

//行为记录（操作记录）
function behaviorRecord(data) {
  const origin = window.location.origin;
  const href = window.location.href;
  const explorerInfo = getExplorerInfo();
  // const arr = ['https://antalcts.careerintlinc.com','http://test.antal.geetemp.com'];
  const arr = ['https://antalcts.careerintlinc.com','https://test-antal.geetemp.com'];
  let originName = '';
  if (origin === arr[0]) {
    originName = `${arr[0]}/atcts_userlog/`
  }else if(origin === arr[1]){
    originName = `${arr[1]}/atcts_userlog/`
  } else {
    originName = '';
  }
  if (!!originName) {
    window.Partial.init({dsn: `${originName}`});
    window.Partial.captureBehavior({
      data: {
        userName: getAccountInfo('userName'),
        ctsCookiesId: getCookie('ctsCookiesId'),
        name: getAccountInfo('name'),
        deptId: getAccountInfo('groupUid'),
        deptName: getAccountInfo('groupName'),
        browser: explorerInfo.type,
        browserVersion: explorerInfo.version,
        ...data,
        url: href
      },
    });
  }
}

//type:1,根据计算今天、本周、上周、本月时间时间段,并返回；type:2,根据日期计算是否是今天、本周、上周、本月中的哪个，都不是返回5时间段
function computeDatePeriod(data, type) {
  let result;
  const df = 'YYYY-MM-DD';
  const now = new Date(); //当前日期
  let nowDayOfWeek = now.getDay(); //今天本周的第几天
  if (nowDayOfWeek === 0) {
    nowDayOfWeek = 7;
  }
  const nowDay = now.getDate(); //当前日
  const nowMonth = now.getMonth(); //当前月
  const nowYear = now.getFullYear(); //当前年
  const endDate = moment(now).format(df);
  const yesterStartDay = moment(new Date(nowYear, nowMonth, nowDay - 1)).format(df);
  const thisWeekStart = moment(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1)).format(df);
  const thisWeekEnd = moment(new Date(nowYear, nowMonth, nowDay + (7 - nowDayOfWeek))).format(df);
  const lastWeekStart = moment(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 6)).format(df);
  const lastWeekend = moment(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek)).format(df);
  const thisMonthStart = moment(new Date(nowYear, nowMonth, 1)).format(df);
  const thisMonthEnd = moment(new Date(nowYear, nowMonth, new Date(nowYear, nowMonth + 1, 0).getDate())).format(df);
  const yesterday = [yesterStartDay, yesterStartDay];//昨天
  const today = [endDate, endDate];//今天
  const thisWeek = [thisWeekStart, thisWeekEnd];//本周
  const lastWeek = [lastWeekStart, lastWeekend];//上周
  const thisMonth = [thisMonthStart, thisMonthEnd];//本月
  if (type === 1) {
    if (data === 0 || data === '0') {
      result = yesterday;
    } else if (data === 1 || data === '1') {
      result = today;
    } else if (data === 2 || data === '2') {
      result = thisWeek;
    } else if (data === 3 || data === '3') {
      result = lastWeek;
    } else if (data === 4 || data === '4') {
      result = thisMonth;
    }
  } else if (type === 2) {
    if (data.join('/') === yesterday.join('/')) {
      result = 0;
    } else if (data.join('/') === today.join('/')) {
      result = 1;
    } else if (data.join('/') === thisWeek.join('/')) {
      result = 2;
    } else if (data.join('/') === lastWeek.join('/')) {
      result = 3;
    } else if (data.join('/') === thisMonth.join('/')) {
      result = 4;
    } else {
      result = 5;
    }
  }
  return result;
}

//根据出生日期获取年龄
export function getAge(start){
    let years = 0,
        diff = 0,
        today = new Date(),
        birth = new Date(start);
    let birthYear = birth.getFullYear(),
        todayYear = today.getFullYear(),
        birthMonth = birth.getMonth(),
        todayMonth = today.getMonth();
    if(birthYear < todayYear){
        years = todayYear - birthYear;
        if(birthMonth > todayMonth){
            diff = -1;
        }else if(birthMonth === todayMonth){
            if(birth.getDate() > today.getDate()){
                diff = -1;
            }
        }
    }
    let age = years+diff;
    return age;
};

//获取两个日期中间所有的月份
export function getWorkTime(start, language = 'zh') {
    const i18n = {
        'year': {
            zh: '年',
            en: 'years'
        },
        'month': {
            zh: '个月',
            en: 'months'
        }
    };
    let end = moment(new Date()).format('YYYY-MM-DD');
    if (start && end) {
        start = moment(start).format('YYYY-MM-DD');
        end = moment(end).format('YYYY-MM-DD');
        let result = '';
        let min = ((new Date(start)).getFullYear()) * 12 + (new Date(start)).getMonth() + 1;
        let max = ((new Date(end)).getFullYear()) * 12 + (new Date(end)).getMonth() + 1;
        if (min < max) {
            let betweenMonth = max - min;
            if (betweenMonth < 12) {
                result = `${parseInt(betweenMonth % 12, 10)}${i18n['month'][language]}`;
            } else {
                result = `${parseInt(betweenMonth / 12, 10)}${i18n['year'][language]}`;
            }
        }
        return result;
    } else {
        return '';
    }
}

//判断当前简历，是获取中文简历还是英文简历
function getResumeType(templateData, languageType) {
  let resumeObj;
  if (languageType === 0) {
    resumeObj = 'cnResume';
  } else if (languageType === 1) {
    resumeObj = 'enResume';
  }
  if (templateData[resumeObj] !== null && templateData[resumeObj] !== '' && JSON.stringify(templateData[resumeObj]) !== '{}') {
    return templateData[resumeObj];
  } else {
    return false;
  }
}

//获取两个日期中间所有的月份
function getMonthBetween(start,end) {
    if(end === '至今' || end === 'Present'){
        end = moment(new Date()).format('YYYY-MM-DD');
    }
    if(start && end && start !== '至今' && start !== 'Present' && start !== '' && end !== ''){
        start = moment(start).format('YYYY-MM-DD');
        end = moment(end).format('YYYY-MM-DD');
        let result = '';
        let min = ((new Date(start)).getFullYear())*12 + (new Date(start)).getMonth()+1;
        let max = ((new Date(end)).getFullYear())*12 + (new Date(end)).getMonth()+1;
        if(min < max){
            let betweenMonth = max - min;
            if(betweenMonth < 12){
                result = `${parseInt(betweenMonth%12, 10)}个月`;
            }else{
                result = parseInt(betweenMonth%12, 10) > 0 ? `${parseInt(betweenMonth/12, 10)}年${parseInt(betweenMonth%12, 10)}个月` : `${parseInt(betweenMonth/12, 10)}年`;
            }
        }
        return result;
    }else{
        return '';
    }
};
//处理中英文名字
function getZhName(eng='',cn=''){
  if(eng && cn){
    return `${eng} (${cn})`
  }else if(eng){
    return eng;
  }else if(cn){
    return cn
  }else{
    return ''
  }
}

export {
  getSomeCitys,
  flatHierarchyOptions,
  parseQueryString,
  urlEncode,
  timestamp,
  selectType,
  getPre,
  getAccountId,
  getActivtyKeys,
  showPageTitle,
  validatorDate,
  showDetailList,
  getButtonResources,
  moneyUnitList,
  moneyUnitObj,
  moneyFormat,
  validatorMoney,
  trackingAnimation,
  behaviorRecord,
  computeDatePeriod,
  getResumeType,
  // disabledEndDate,
  // disabledStartDate
  getMonthBetween,
  getZhName
};
