/**
 * Created by CI11840 on 2019/3/4.
 */
import Model from "../model";

import {
  queryAnnouncementList,
  queryExpiredNotFinishPlanList,
  queryCurPeriodPlanList,
  recordPunchTime,
  savePlan,
  queryFinishInfo,
  // getSpecifiedAcccountGroup,
  getAccountsByGroupId,
  queryTeamFinishInfo,
  updateStatus,
  finishRate,
  isExistReporter,
  findById,
  updatePlan,
  //工作台完成情况活动统计列表查询(沟通联系，推荐初审，初审反馈，客户反馈，确认签署offer
  queryActivityByCondition,
  //工作台完成情况新建职位列表查询
  queryNewPositionByCondition,
  //工作台完成情况面试安排第一轮列表查询
  queryInterviewFirstByCondition,
  //工作台完成情况新建简历或有效候选人列表查询
  queryCandidateOperateLogsByCondition,
  //工作台完成情况活动统计列表查询(沟通联系）
  queryCommunRelationByCondition

} from '../../services/api3'

export default Model.getInstance(
  class extends Model {
    namespace = "workbench";

    state = {
      //公告信息列表查询的数据
      informationListStore: {
        records: []
      },
      //过期未完成列表
      notFinishPlanList: {
        records: []
      },
      //登录时间
      loginTimeData: '',
      //当前计划列表
      curPeriodPlanListStoreData: {
        records: []
      },
      posSearchModel: {visible: false},//职位高级搜索弹框
      savePlanStoreData: {},
      personalFinishInfoStore: {
        acceptOfferList: [],
        communRelationList: [],
        createResumeList: [],
        customereedbackList: [],
        effectiveCandidateList: [],
        interviewFirstList: [],
        newPositionList: [],
        recommandTrialList: [],
        trialFirstList: []
      },
      //某个人汇报线所在的组
      // specifiedAcccountGroupStore: [],
      //根据团队找账号列表
      accountsByGroupIdStore: [],
      //团队工作台完成情况查询
      queryTeamFinishStore: {
        acceptOfferList: [],
        communRelationList: [],
        createResumeList: [],
        customereedbackList: [],
        effectiveCandidateList: [],
        interviewFirstList: [],
        newPositionList: [],
        recommandTrialList: [],
        trialFirstList: []
      },
      upDateStatusStore: {},
      finishRateStore: '',
      isExistReporterStore: false,
      //查询计划
      findByIdStore: {},
      editHandelDataStore: {},
      updatePlanStore: {},
      queryCompleteDetail: {records: []},
      // //获取团队id
      // getSelectedGroupIdStore:'',
      // //获取顾问id
      // getSelectedConsultantStore:'',
      // //获取团队Tabs的key
      // getTeamTabsStore:1,
      // getTeamDatePickerStore:{}
      //获取工作计划tabs的key
      getworkPlanTabsStore: 1,
      getworkPlanSelectedStore: 8,
      getworkPlanDatePickerStore: {
        startTime: '',
        endTime: ''
      }
    };

    actions = {
      //公告信息列表
      queryAnnouncementListData(data){
        this.dispatch({
          type: "workbench/searchInformationList",
          payload: {listLoading: true}
        });
        queryAnnouncementList(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/searchInformationList",
              payload: {...res.result, listLoading: false}
            });
          }
        });
      },
      //过期未完成列表
      queryExpiredNotFinishPlanListData(data){
        queryExpiredNotFinishPlanList(data).then(res => {
          this.dispatch({
            type: "workbench/searchExpiredNotFinishPlanList",
            payload: {notFinishLoading: true}
          });
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/searchExpiredNotFinishPlanList",
              payload: {...res.result, notFinishLoading: false}
            });
          }
        });
      },
      //当前计划列表     
      queryCurPeriodPlanListData(data){
        this.dispatch({
          type: "workbench/searchqueryCurPeriodPlanList",
          payload: {CurPeriodPlanLoading: true}
        });
        queryCurPeriodPlanList(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/searchqueryCurPeriodPlanList",
              payload: {...res.result, CurPeriodPlanLoading: false}
            });
          }
        });
      },
      //登录时间     
      loginTime(){
        recordPunchTime().then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/getLoginTime",
              //payload: {time:res.result||''}
              payload: res.result
            });
          }
        });
      },
      //保存计划
      savePlanAction(data){
        savePlan(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/savePlanReducers",
              payload: res.result
            });
          }
        });
      },
      //工作台完成情况查询
      queryFinishInfoAction(data, type){//type 1是个人 2是团队
        if (type === 1) {
          this.dispatch({
            type: "workbench/personalFinishInfo",
            payload: {completeListLoading: true}
          });
        } else if (type === 2) {
          this.dispatch({
            type: "workbench/queryTeamFinish",
            payload: {completeListLoading: true}
          });
        }
        queryFinishInfo(data).then(res => {
          if (res.code === 200) {
            if (type === 1) {
              this.dispatch({
                type: "workbench/personalFinishInfo",
                payload: {...res.result, completeListLoading: false}
              });
            } else if (type === 2) {
              this.dispatch({
                type: "workbench/queryTeamFinish",
                payload: {...res.result, completeListLoading: false}
              });
            }

          }
        });
      },
      // //某个人所在汇报线上的组
      // getSpecifiedAcccountGroupAction(data){
      //   this.state.specifiedAcccountGroupStore = [];
      //   getSpecifiedAcccountGroup(data).then(res => {
      //     if (res.code === 200) {
      //       this.dispatch({
      //         type: "workbench/specifiedAcccountGroup",
      //         payload: res.result || []
      //       });
      //     }
      //   });
      // },
      //根据团队找账号列表
      getAccountsByGroupIdAction(data){
        getAccountsByGroupId(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/accountsByGroupId",
              payload: res.result
            });
          }
        });
      },
      //团队工作台完成情况查询
      getQueryTeamFinishInfoAction(data){
        this.dispatch({
          type: "workbench/queryTeamFinish",
          payload: {completeListLoading: true}
        });
        queryTeamFinishInfo(data).then(res => {
          //console.log("uuuuuuuuuuuu",res);
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/queryTeamFinish",
              payload: {...res.result, completeListLoading: false}
            });
          }
        });
      },
      updateStatusAction(data){
        updateStatus(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/updateStatusReducer",
              payload: res.result
            });
          }
        });
      },
      //查询当期完成率
      finishRateAction(data){
        finishRate(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/finishRateReducer",
              payload: res.result
            });
          }
        });
      },
      async getisExistReporter(){
        let resultData = {};
        await isExistReporter().then(res => {
          resultData = res;
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/isExistReporterReducer",
              payload: res.result
            });
          }
        });
        return resultData;
      },
      //查询计划
      findByIdAction(data){
        findById(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/findByIdReducer",
              payload: res.result
            });
          }
        });
      },
      //修改计划
      updatePlanAction(data){
        updatePlan(data).then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "workbench/updatePlanReducer",
              payload: res.result
            });
          }
        });
      },

      async completeDetailAction(data, type){
        this.dispatch({
          type: "workbench/completeDetailReducer",
          payload: {detailListLoading: true}
        });
        let resultData = {};
        switch (type) {
          //工作台完成情况活动统计列表查询(沟通联系，推荐初审，初审反馈，客户反馈，确认签署offer)
          case 1:
            await queryActivityByCondition(data).then(res => {
              resultData = res;
            });
            break;
          //工作台完成情况新建职位列表查询
          case 2:
            await queryNewPositionByCondition(data).then(res => {
              resultData = res;
            });
            break;
          //工作台完成情况面试安排第一轮列表查询
          case 3:
            await queryInterviewFirstByCondition(data).then(res => {
              resultData = res;
            });
            break;
          //工作台完成情况新建简历或有效候选人列表查询
          case 4:
            await queryCandidateOperateLogsByCondition(data).then(res => {
              resultData = res;
            });
            break;
          //团队工作台完成情况活动统计列表查询(沟通联系，推荐初审，初审反馈，客户反馈，确认签署offer)
          case 5:
            await queryActivityByCondition(data).then(res => {
              resultData = res;
            });
            break;
          case 6:
            //工作台完成情况新建简历或有效候选人列表查询
            await queryCandidateOperateLogsByCondition(data).then(res => {
              resultData = res;
            });
            break;
          //工作台完成情况面试安排第一轮列表查询
          case 7:
            await queryInterviewFirstByCondition(data).then(res => {
              resultData = res;
            });
            break;
          //工作台完成情况新建职位列表查询
          case 8:
            await queryNewPositionByCondition(data).then(res => {
              resultData = res;
            });
            break;
          //工作台完成情况沟通联系列表查询
          case 9:
            await queryCommunRelationByCondition(data).then(res => {
              resultData = res;
            });
            break;
          //工作台完成情况沟通联系列表查询
          case 10:
            await queryCommunRelationByCondition(data).then(res => {
              resultData = res;
            });
            break;
          default:
            break;
        }
        if (resultData && resultData.code === 200) {
          this.dispatch({
            type: "workbench/completeDetailReducer",
            payload: {...resultData.result || {records: []}, detailListLoading: false}
          });
        }
        return resultData;
      }
    };

    reducers = {
      //公告信息列表查询的数据
      searchInformationList(state, {payload: pathname}) {
        return {
          ...state,
          informationListStore: pathname
        };
      },
      searchExpiredNotFinishPlanList(state, {payload: pathname}) {
        return {
          ...state,
          notFinishPlanList: pathname
        };
      },
      searchqueryCurPeriodPlanList(state, {payload: pathname}){
    console.log(state,pathname,'curPeriodPlanListStoreData2')

        return {
          ...state,
          curPeriodPlanListStoreData: pathname
        }
      },
      getLoginTime(state, {payload: pathname}){
        return {
          ...state,
          loginTimeData: pathname
        }
      },
      savePlanReducers(state, {payload: pathname}){
        return {
          ...state,
          savePlanStoreData: pathname
        }
      },
      //职位高级搜索弹框
      showPosSearchModel(state, {payload: pathname}) {
        return {
          ...state,
          posSearchModel: pathname
        };
      },
      personalFinishInfo(state, {payload: pathname}){
        return {
          ...state,
          personalFinishInfoStore: pathname
        };
      },
      //某个人汇报线所在的组
      // specifiedAcccountGroup(state, {payload: pathname}){
      //   return {
      //     ...state,
      //     specifiedAcccountGroupStore: pathname
      //   };
      // },
      //根据团队找账号列表
      accountsByGroupId(state, {payload: pathname}){
        return {
          ...state,
          accountsByGroupIdStore: pathname
        };
      },
      //团队工作台完成情况查询
      queryTeamFinish(state, {payload: pathname}){
        return {
          ...state,
          queryTeamFinishStore: pathname
        };
      },
      updateStatusReducer(state, {payload: pathname}){
        return {
          ...state,
          upDateStatusStore: pathname
        };
      },
      finishRateReducer(state, {payload: pathname}){
        return {
          ...state,
          finishRateStore: pathname
        };
      },
      isExistReporterReducer(state, {payload: pathname}){
        return {
          ...state,
          isExistReporterStore: pathname
        };
      },
      findByIdReducer(state, {payload: pathname}){
        return {
          ...state,
          findByIdStore: pathname
        };
      },
      editHandelDataReducer(state, {payload: pathname}){
        console.log('pathname', pathname);
        return {
          ...state,
          editHandelDataStore: pathname
        }
      },
      //修改计划
      updatePlanReducer(state, {payload: pathname}){
        return {
          ...state,
          updatePlanStore: pathname
        }
      },
      //工作台完成情况详细
      completeDetailReducer(state, {payload: pathname}){
        return {
          ...state,
          queryCompleteDetail: {...state.queryCompleteDetail, ...pathname}
        }
      },
      //获取工作计划今天、本周的key
      getworkPlanTabsReducer(state, {payload: pathname}){
        return {
          ...state,
          getworkPlanTabsStore: pathname
        }
      },
      //获取工作计划select中的值
      getworkPlanSelectedReducer(state, {payload: pathname}){
        return {
          ...state,
          getworkPlanSelectedStore: pathname
        }
      },
      getworkPlanDatePickerReducer(state, {payload: pathname}){
        return {
          ...state,
          getworkPlanDatePickerStore: pathname
        }
      }
    };
  }
);