/**
 * Created by CI11840 on 2019/3/25.
 */
import XHR from "services";
import {getAccountId} from '../utils/util'
// //查询当前团队名称
// export async function queryGroupName(params) {
//   return XHR({
//     url: '/crm/v1.0/authcenter/group/web/query/' + params,
//     method: 'GET'
//   });
// }
//
// // 顾问总金额
// export async function queryAdviserAmount(params) {
//   return XHR({
//     url: '/crm/v1.0/position/work/web/adviser/amount',
//     body: params,
//     method: 'POST',
//   });
// }
//
// //登录
// export async function accountLogin(params) {
//   return XHR({
//     url: '/crm/v1.0/authcenter/account/web/login?time=' + new Date().getTime(),
//     body: params,
//     dataType: 'form',
//   });
// }
//
// //登出
// export async function accountLogout(params) {
//   return XHR({
//     url: '/crm/v1.0/authcenter/account/web/logout',
//     body: params
//   });
// }

let prefix = '/cts/v1.0';
// let prefix = '';

// 修改密码(已加网关)
export async function updatePassword(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/updatePassword',
    body: params,
    dataType: 'form',
    method: 'POST'
  });
}

//登录(已加网关)
export async function loginAccount(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/login',
    body: params,
    dataType: 'form',
    method: 'POST'
  });
}
//退出登录(已加网关)
export async function loginOut() {
  return XHR({
    url: prefix + '/authcenter/account/web/logout',
    method: 'POST'
  });
}

export async function authingLoginOut() {
  return XHR({
    url: prefix + '/authcenter/account/web/authingLogoutUrl',
    method: 'GET'
  });
}

//根据CI账号、姓名、英文名、手机号等关键字查询账号(已加网关)
export async function getAccountsBySearchKey(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/getAccountsBySearchKey/' + params,
    method: 'GET'
  });
}
//绑定登录(已加网关)
export async function bindAccount(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/bindAccount',
    body: params,
    dataType: 'form',
    method: 'POST'
  });
}

//查找职位自定义字段(已加网关)
export async function getPositionTemplateFields() {
  return XHR({
    url: prefix + '/position/web/getPositionTemplateFields',
    method: 'GET'
  });
}

//新建职位(已加网关)
export async function createPosition(params) {
  return XHR({
    url: prefix + '/position/web/save',
    body: params,
    method: 'POST'
  });
}

//编辑职位(已加网关)
export async function upDatePosition(params) {
  return XHR({
    url: prefix + '/position/web/update',
    body: params,
    method: 'POST'
  });
}

//删除职位
export async function deletePosition(params) {
  return XHR({
    url: prefix + '/position/web/delPosition',
    body: params,
    method: 'POST'
  });
}


//职位列表查询(已加网关)
export async function queryPositionList(params) {
  return XHR({
    url: prefix + '/position/web/queryPositionList',
    body: params,
    method: 'POST'
  });
}

//查看职位信息
export async function queryPositionInfo(params) {
  return XHR({
    url: prefix + '/position/web/query/' + params,
    method: 'GET'
  });
}
//获取职位详情额外字段
export async function queryPositionOtherFields(params) {
  return XHR({
    url: prefix + '/position/web/queryPositionOtherFields/' + params,
    method: 'GET'
  });
}

//获取所有账号(已加网关)
export async function getPositionAccounts(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/getAccounts/' + params,
    method: 'GET'
  });
}

//获取所有账号(已加网关) 客户高级搜索
export async function customerGetPositionAccounts(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/getAllAccounts/' + params,
    method: 'GET'
  });
}

//获取账号(已加网关)
export async function getPositionAccounts1(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/getAccounts/' + params,
    method: 'GET'
  });
}
//根据Id集合搜索账号(已加网关)
export async function getAccountsInIds(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/getAccountsInIds',
    body: params,
    method: 'POST'
  });
}

//查询多个人的汇报线(已加网关)
export async function getSpecifiedAcccountGroupBatch(params) {
  return XHR({
    url: prefix + '/authcenter/group/web/getSpecifiedAcccountGroupBatch',
    body: params,
    method: 'POST'
  });
}
//查询多个人在职位上是否有候选人或顾问
export async function allCancelSelectAccount(params) {
  return XHR({
    url: prefix + '/position/web/allCancel',
    body: params,
    method: 'POST'
  });
}


//查询某个人的汇报线(已加网关)
export async function getGroupsByAccountId(accountId, reporterId = null) {
  return XHR({
    url:
      prefix +
      "/authcenter/group/web/getSpecifiedAcccountGroup/" +
      accountId +
      "/" +
      reporterId,
    method: "GET",
  });
}

//查找职位流程列表(已加网关)
export async function queryPositionFlows(params) {
  return XHR({
    url: prefix + '/position/web/queryPositionFlows',
    body: params,
    method: 'GET'
  });
}

//查找单个职位流程(已加网关)
export async function queryPositionFlowDetail(params) {
  return XHR({
    url: prefix + '/position/web/queryFlow/' + params,
    method: 'GET'
  });
}
//查找候选人报告模板列表(已加网关)
export async function queryReportTemplates() {
  return XHR({
    url: prefix + '/candidate/reportTemplate/web/queryList',
    method: 'GET'
  });
}

//团队树形结构(已加网关)
export async function deptTreeList() {
  return XHR({
    url: prefix + '/authcenter/group/web/treeList',
    method: 'GET'
  });
}

//根据团队id查找当前团队及子团队的所有员工(已加网关)
export async function getAccountOfCurAndChildGroup(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/getAccountOfCurAndChildGroup',
    body: params,
    method: 'POST'
  });
}

//根据标签类型查找标签(已加网关)
export async function getCandidateTags(params) {
  return XHR({
    url: prefix + '/candidate/candidateTag/web/getCandidateTags/' + params.type + "?delStatus=" + params.delStatus,
    method: 'GET'
  });
}

//查询城市信息(已加网关)
export async function getCityTags() {
  return XHR({
    url: prefix + '/candidate/cityTag/web/getCityTags',
    method: 'GET'
  });
}

//根据标签类型和标签名称查询标签(已加网关)
export async function getCandidateTagsByName(params) {
  return XHR({
    url: prefix + '/candidate/candidateTag/web/getCandidateTagsByName/' + params.tagType,
    body: {tagName: params.tagName,delStatus:params.delStatus},
    method: 'GET'
  });
}

//根据城市名称查询标签(已加网关)
export async function getCityTagsByName(params) {
  return XHR({
    url: prefix + '/candidate/cityTag/web/getCityTagsByName',
    body: {tagName: params.tagName},
    method: 'GET'
  });
}

//保存常用标签(已加网关)
export async function saveAccountCommonTag(params) {
  return XHR({
    url: prefix + '/candidate/accountCommonTag/web/save',
    body: params,
    method: 'POST'
  });
}
//查看常用标签(已加网关)
export async function queryAccountCommonTag(type) {
  const uid = getAccountId();
  return XHR({
    url: prefix + `/candidate/accountCommonTag/web/getByAccountId/${uid}/${type}`,
    // body: params,
    method: 'GET'
  });
}

//获取流程状态数量(已加网关)
export async function getPositionFlowProgress(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/getFlowProgress',
    body: params,
    method: 'GET'
  });
}

//获取流程节点对应候选人列表
export async function getCandidatesByStatus(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/getCandidatesByStatus',
    body: params,
    method: 'POST'
  });
}

//获取流程节点对应候选人活动列表
export async function getCandidatesActivities(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/getCandidatesActivities',
    body: params,
    method: 'POST'
  });
}

//获取流程节点各个表单的初始化数据
export async function getCandidateForm(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/getCandidateForm',
    body: params,
    method: 'POST'
  });
}

//保存各个流程节点提交的表单(已加网关)
export async function saveFlowNodes(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/saveFlowNodes',
    body: params,
    method: 'POST'
  });
}

//修改各个流程节点提交的表单(已加网关)
export async function updateFlowNode(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/updateFlowNode',
    body: params,
    method: 'POST'
  });
}

//根据职位查询招聘顾问(已加网关)
export async function getPositionAdvisers(params) {
  return XHR({
    url: prefix + '/position/web/queryAdvisers',
    body: {positionId: params},
    method: 'GET'
  });
}

//修改职位状态
export async function updatePositionStatus(params) {
  return XHR({
    url: prefix + '/position/web/updateStatus',
    body: params,
    method: 'POST'
  });
}

//修改职位组织
export async function updatePositionGroup(params) {
  return XHR({
    url: prefix + '/position/web/updateGroup',
    body: params,
    method: 'POST'
  });
}

//当前职位职位主管所有交接人(已加网关)
export async function getCounterpartListOfPositionId(params) {
  return XHR({
    url: prefix + '/position/web/counterpartList/' + params,
    method: 'GET'
  });
}

//单个职位批量分配交接人(已加网关)
export async function updateAssignCounterpartBatch(params) {
  return XHR({
    url: prefix + '/position/web/assignCounterpartBatch',
    body: params,
    method: 'POST'
  });
}
//职位批量分配交接人
export async function batchAddCounterpart(params) {
  return XHR({
    url: prefix + '/position/web/batchAddCounterpart',
    body: params,
    method: 'POST'
  });
}
//自主成为交付对接人
export async function onTurnIntoCounterpart(params) {
  return XHR({
    url: prefix + '/position/web/turnIntoCounterpart',
    body: params,
    method: 'POST'
  });
}
//根据职位和对接人查询招聘顾问(已加网关)
export async function searchPositionAdvisers(params) {
  return XHR({
    url: prefix + '/position/web/queryPositionAdvisers',
    body: params,
    method: 'GET'
  });
}

//当前职位我是交接人所有的招聘顾问(已加网关)
export async function getAdviserListOfPositionId(params) {
  return XHR({
    url: prefix + '/position/web/adviserList/' + params,
    method: 'GET'
  });
}

//单个职位批量分配招聘顾问(已加网关)
export async function assignAdviserBatch(params) {
  return XHR({
    url: prefix + '/position/web/assignAdviserBatch',
    body: params,
    method: 'POST'
  });
}

//多个职位分配招聘顾问(已加网关)
export async function multiPositionAssignAdviserBatch(params) {
  return XHR({
    url: prefix + '/position/web/multiPositionAssignAdviserBatch',
    body: params,
    method: 'POST'
  });
}

//移交招聘顾问(已加网关)
export async function transferAdviser(params) {
  return XHR({
    url: prefix + '/position/web/transferAdviser',
    body: params,
    method: 'POST'
  });
}

//移交候选人(已加网关)
export async function transferCandidates(params) {
  return XHR({
    url: prefix + '/position/web/transferCandidates',
    body: params,
    method: 'POST'
  });
}

//根据职位和招聘顾问查询候选人(已加网关)
export async function searchCandidatesByAdviserId(params) {
  return XHR({
    url: prefix + '/position/web/queryCandidatesByAdviserId',
    body: params,
    method: 'GET'
  });
}

//下载候选人报告
export async function downloadReportFile(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/downloadReportFile',
    method: 'GET',
    body: params,
    dataType: 'download'
  });
}

//查看候选人报告
export async function getCandidateReport(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/getCandidateReport',
    method: 'GET',
    body: params,
  });
}

//保存候选人报告
export async function saveCandidateReport(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/saveCandidateReport',
    method: 'POST',
    body: params,
  });
}

//查找对应人的汇报线所在的部门
export async function getReportGroup(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/getReportGroup',
    method: 'GET',
    body: params,
  });
}

// 获取开票路径
export async function getAsyncTicketUrl(params) {
  return XHR({
    url: prefix + '/position/web/getAsyncTicketUrl',
    method: 'GET',
    body: params,
  });
}

// 获取登录日志
export async function getLoginLogMonitorReport(params) {
  return XHR({
    url: prefix + '/authcenter/monitor/web/getLoginLogMonitorReport',
    method: 'POST',
    body: params,
  });
}

// 导出登录日志表格
export async function exportLoginLogMonitorReport(params) {
  return XHR({
    url: prefix + '/authcenter/monitor/web/exportLoginLogMonitorReport',
    method: 'GET',
    body: params,
    dataType: 'download'
  });
}

// 查询获取行为日志
export async function getMonitorPage(params) {
  return XHR({
    url: prefix + '/candidate/monitor/web/getMonitorPage',
    method: 'POST',
    body: params,
  });
}

// 导出日志监控报表
export async function exportMonitorReport(params) {
  return XHR({
    url: prefix + '/candidate/monitor/web/exportMonitorReport',
    method: 'GET',
    body: params,
    dataType: 'download'
  });
}

// 检查流程节点
export async function checkFlowNodes(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/checkFlowNodes',
    method: 'POST',
    body: params,
  });
}

// 根据城市id找下级所有城市
export async function getCityTagsByTreePathIds(params) {
  return XHR({
    url: prefix + '/candidate/cityTag/web/getCityTagsByTreePathIds',
    method: 'GET',
    body: params,
  });
}

// 根据标签id找下级所有标签
export async function getCandidateTagsByTreePathIds(params) {
  return XHR({
    url: prefix + '/candidate/candidateTag/web/getCandidateTagsByTreePathIds',
    method: 'GET',
    body: params,
  });
}

// 删除做单流程活动
export async function deleteActivity(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/deleteActivity',
    method: 'GET',
    body: params
  });
}

// 查询客户关联的职位（招聘项目）
export async function findCustPositionPojoList(params) {
  return XHR({
    url: prefix + '/position/customer/web/findCustPositionPojoList',
    method: 'POST',
    body: params,
  });
}

// 查询客户合同(合同)
export async function findCustContractPojoList(params) {
  return XHR({
    url: prefix + '/position/customer/web/findCustContractPojoList',
    method: 'POST',
    body: params,
  });
}

// 查询客户合同类型
export async function findCustContractTypeList(params) {
  return XHR({
    url: prefix + '/position/customer/web/findCustContractTypeList/' + params,
    method: 'GET'
  });
}

// 查询开票公司
export async function findCustInvtitlePojoList(params) {
  return XHR({
    url: prefix + '/position/customer/web/findCustInvtitlePojoList',
    method: 'POST',
    body: params,
  });
}

// 查询客户信息详情
export async function findCustomerById(params) {
  return XHR({
    url: prefix + '/position/customer/web/findById/' + params,
    method: 'GET',
  });
}

// 分页查询客户信息
export async function findCustomerList(params) {
  return XHR({
    url: prefix + '/position/customer/web/findCustomerList',
    method: 'POST',
    body: params,
  });
}

// 查询操作日志(客户更新记录)
export async function queryCustomerLogsPage(params) {
  return XHR({
    url: prefix + '/position/customer/web/queryLogsPage',
    method: 'POST',
    body: params,
  });
}

// 收藏客户公司
export async function collectCustomer(params) {
  return XHR({
    url: prefix + '/position/customer/web/collect',
    method: 'POST',
    body: params,
  });
}

// 取消收藏客户公司
export async function cancelCollectCustomer(params) {
  return XHR({
    url: prefix + '/position/customer/web/cancelCollect',
    method: 'GET',
    body: params,
  });
}

// 从工商库单独更新信息
export async function updateCustomerQxInfo(params) {
  return XHR({
    url: prefix + '/position/customer/web/updateQxInfo',
    method: 'GET',
    body: params,
  });
}

// 查询市场部线索数据
export async function findMarketClues(params) {
  return XHR({
    url: prefix + '/position/customer/web/findMarketClues',
    method: 'POST',
    body: params,
  });
}

// 认领线索
export async function claimClue(params) {
  return XHR({
    url: prefix + '/position/customer/web/claimClue',
    method: 'GET',
    body: params,
  });
}

// 更新法务弹窗次数
export async function updateGuideCount(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/updateGuideCount',
    method: 'GET',
    body: params
  });
}
// 查看职位上候选人信息
export async function fetchShowPositionCandidate(params) {
  return XHR({
    url: prefix + '/position/web/showPositionCandidate',
    method: 'GET',
    body: params
  });
}

// 查寻候选人概览各个节点数
export async function queryNodeCountByPositionId(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/queryNodeCountByPositionId',
    method: 'POST',
    body: params
  });
}

// 查寻候选人概览列表
export async function queryCandidateListByPositionId(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/queryCandidateListByPositionId',
    method: 'POST',
    body: params
  });
}
