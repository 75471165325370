import Model from "../model";
import {history} from "root";
import {setCookie} from '../../utils/cookie'

// import {clearAuthority, setAuthority} from "utils/authority";
// import {reloadAuthorized} from "utils/authorized";
// import {USER_STORE_KEY} from "contants";
import {
  loginAccount,
  loginOut,
  authingLoginOut,
  getAccountsBySearchKey,
  bindAccount
} from '../../services/api1'
import {behaviorRecord} from '../../utils/util'
let helpCenter = {
  uid: '99',
  name: '帮助中心',
  type: 1,
  code: 'instructions',
  resources: [
    // {
    //   uid: '991',
    //   parentUid: '99',
    //   name: '常见问题',
    //   type: 1,
    //   code: "in-1"
    // }, 
    {
      uid: '992',
      parentUid: '99',
      name: '手册文档',
      type: 1,
      code: "in-2"
    }, {
      uid: '993',
      parentUid: '99',
      name: '意见与反馈方式',
      type: 1,
      code: "in-3"
    }
  ]
};
export default Model.getInstance(
  class extends Model {
    namespace = "user";
    state = {
      userInfo: {},
      bindAccountList: {records: [], loading: false}
      // user:()=>{console.log(234,JSON.parse(localStorage.getItem('currentUser')));return true}
    };
    actions = {
      async login(param) {
        localStorage.removeItem('currentUser');
        let resultData = {};
        await loginAccount(param).then(res => {
          resultData = res;
          if (res.code === 200) {
            let getResult = res.result;
            if (!!getResult.role) {
              let role = JSON.parse(getResult.role);
              if (role.perm && role.perm.resources) {
                role.perm.resources.push(helpCenter);
                getResult.role = JSON.stringify(role);
              }
              if (role.perm && role.perm.buttonResources) {
                let buttonResources = {};
                role.perm.buttonResources.forEach(item => {
                  buttonResources[item.code] = item;
                  localStorage.setItem('buttonResources', JSON.stringify(buttonResources));
                })
              } else {
                localStorage.setItem('buttonResources', JSON.stringify({}));
              }
            }

            this.dispatch({
              type: "user/setUser",
              payload: getResult
            });
            localStorage.setItem('currentUser', JSON.stringify(getResult));
            if (getResult.loginType === 'first') {
              history.replace('/user/password');
            } else {
              if (res.result.roleType !== 'superadmin') {
                behaviorRecord({type: 'login', state: '成功'});
                let isPlatAdmin = (res.result.roleType === 'platadmin');
                if (isPlatAdmin) {
                  history.replace("/operation/:type");
                } else {
                  history.replace("/");
                }
              }
            }

            if (param.autoLogin) {
              setCookie('userName', param.userName, 7);
            } else {
              setCookie('userName', '', -1);
            }
          }
        });
        return resultData;
        //store user info to redux

      },
      async bindAccountLogin(param) {
        let resultData = {};
        await bindAccount(param).then(res => {
          resultData = res;
          if (res.code === 200) {
            let getResult = res.result;
            if (!!getResult.bindAccount.role) {
              let role = JSON.parse(getResult.bindAccount.role);
              if (role.perm && role.perm.resources) {
                role.perm.resources.push(helpCenter);
                getResult.bindAccount.role = JSON.stringify(role);
              }
              if (role.perm && role.perm.buttonResources) {
                let buttonResources = {};
                role.perm.buttonResources.forEach(item => {
                  buttonResources[item.code] = item;
                  localStorage.setItem('buttonResources', JSON.stringify(buttonResources));
                })
              } else {
                localStorage.setItem('buttonResources', JSON.stringify({}));
              }
            }
            this.dispatch({
              type: "user/setUser",
              payload: getResult
            });
            localStorage.setItem('currentUser', JSON.stringify(res.result));
            behaviorRecord({type: 'login', state: '成功'});
            let isPlatAdmin = (res.result.roleType === 'superadmin' && res.result.bindAccount.roleType === 'platadmin');
            if (isPlatAdmin) {
              history.replace("/operation/:type");
            } else {
              history.replace("/");
            }
            if (param.autoLogin) {
              setCookie('userName', param.userName, 7);
            } else {
              setCookie('userName', '', -1);
            }
          }
        });
        return resultData;
      },

      async isLogin() {
        const {user} = this.getState();
        !Object.keys(user).length ? history.replace("/user/login") : void 0;
      },

      async logout() {
        //clear user info from redux
        loginOut().then(res => {
          if (res.code === 200) {
            this.dispatch({
              type: "user/setUser",
              payload: {}
            });
            localStorage.removeItem('currentUser');

            // authingLoginOut
            authingLoginOut().then(res => {
              if (res.code === 200) {
                window.location.href = res.result
              }
            })
            // history.replace("/user/login");
            // window.location.reload(true);
          }
        });
      },
      queryAccountsBySearchKey(data){
        this.dispatch({
          type: "user/onchangeBindAccountList",
          payload: {loading: true}
        });
        getAccountsBySearchKey(data).then(res => {
          if (res && res.code === 200) {
            this.dispatch({
              type: "user/onchangeBindAccountList",
              payload: {records: res.result, loading: false}
            });
          }
        })
      }
    };

    reducers = {
      setUser(state, {payload}) {
        let userInfo;
        if (JSON.stringify(payload) === '{}') {
          userInfo = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
        } else {
          userInfo = payload;
        }
        return {...state, userInfo};
      },
      onchangeBindAccountList(state, {payload: pathname}){
        return {
          ...state,
          bindAccountList: {...state.bindAccountList, ...pathname}
        };
      }
    };
  }
);
