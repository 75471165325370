import React from "react";
import {Switch, Route} from "react-router-dom";
import Loadable from 'react-loadable'
import HeaderNavLayout from "layout/header-nav-layout";
// import SiderNavLayout from "layout/sider-nav-layout";
import UserLayout from "layout/user-layout";
import UserLogin from "pages/user/login";
import UserPassword from 'pages/user/password';
import {Exception403, Exception404, Exception500} from "components/exception";
// // 工作台
// const Workbench = Loadable({
//   loader: () => import("pages/workbench"),
//   loading(){
//     // return <div>加载中</div>
//     return <div/>
//   }
// });
//客户
const Customer = Loadable({
  loader: () => import('pages/customer'),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//职位
const Position = Loadable({
  loader: () => import("pages/position"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//职位详情
const PositionDetail = Loadable({
  loader: () => import("pages/position/detail"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//人选
const Candidate = Loadable({
  loader: () => import("pages/candidate"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//候选人详情
const CandidateDetail = Loadable({
    loader: () => import("pages/candidate-detail"),
    loading(){
        // return <div>加载中</div>
        return <div/>
    }
});
//报表先隐藏
//报表
const Reports = Loadable({
  loader: () => import("pages/reports"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//财务
const Finance = Loadable({
  loader: () => import("pages/finance"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//运营
const Operation = Loadable({
  loader: () => import("pages/operation"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//帮助中心
const Instructions = Loadable({
  loader: () => import("pages/instructions"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//明细详情
const Detail = Loadable({
  loader: () => import("pages/detail"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});
//客户详情
const CustomerDetail = Loadable({
  loader: () => import("pages/customer/detail"),
  loading(){
    // return <div>加载中</div>
    return <div/>
  }
});

// 鹰眼简历对比页
const ChannelCompare = Loadable({
    loader: () => import("components/candidate-detail/channel-compare/channel-compare.jsx"),
    loading(){
        // return <div>加载中</div>
        return <div/>
    }
});

// import Position from "pages/position"
// import Operation from "pages/operation"
// import Candidate from "pages/candidate"


// const authorities = ["admin"];
const Icon = (name) => {
  return <i className={`iconfont ${name} mr4`}/>;
};
const Routes = () => (
  <Switch>
    <Route path="/user" component={UserLayout}>
      <Route path="/user" redirect="/user/login" exact={true}/>
      <Route path="/user/login" component={UserLogin}/>
      <Route path="/user/password" component={UserPassword}/>
    </Route>
    <Route path="/detail" component={Detail}/>
    <Route path="/403" component={Exception403}/>
    <Route path="/404" component={Exception404}/>
    <Route path="/500" component={Exception500}/>
    <Route path="/" component={HeaderNavLayout}>
      <Route path="/" redirect="/position/1" exact={true}/>
      <Route path="http://erp.careerintlinc.com" icon={Icon('icon-desktop')} name="内网门户" target="_blank"/>
      {/* <Route path="/workbench/:type" type="a" icon={Icon('icon-notebook')} component={Workbench} name="工作台"auth="workbench"/> */}
      <Route path="/customer/:type" type="a" icon={Icon('icon-gongsi1')} component={Customer} name="客户" auth="customer"/>
      <Route path="/position/:type" type="a" icon={Icon('icon-idcard')} component={Position} name="职位" auth="position"/>
      <Route path="/candidate/:type" type="a" icon={Icon('icon-team')} component={Candidate} name="候选人" auth="candidate"/>
      <Route path="/reports/:type" type="a" icon={Icon('icon-file-text')} component={Reports} name="报表" auth="reports"/>
      <Route path="/finance/:type" type="a" icon={Icon('icon-propertysafety')} component={Finance} name="财务"auth="finance"/>
      <Route path="/operation/:type" type="a" icon={Icon('icon-fund')} component={Operation} name="运营中心"auth="operation"/>
      <Route path="/instructions/:type" type="a" icon={Icon('icon-warning-circle')} component={Instructions} name="帮助中心"auth="instructions"/>
      <Route path="/candidateDetail" component={CandidateDetail}/>
      <Route path="/positionDetail" component={PositionDetail}/>
      <Route path="/similarPosition" component={Position}/>
      <Route path="/customerDetail"  component={CustomerDetail}/>
      <Route path="/channelCompare" component={ChannelCompare}/>
      <Route component={Exception404}/>
    </Route>
  </Switch>
);

export default Routes;
