/**
 * Created by CI11840 on 2019/3/5.
 */
import Model from "../model";

export default Model.getInstance(
  class extends Model {
    namespace = "reports";

    state = {};

    actions = {};

    reducers = {};
  }
);