/**
 * Created by CI17326 on 2019/4/23.
 */
import XHR from "services";

let prefix = '/cts/v1.0';

//公告信息列表
export async function queryAnnouncementList(params) {
  return XHR({
    url: prefix + '/common/announcement/web/queryAnnouncementList',
    method: 'POST',
    body: params
  });
}

//过期未完成列表
export async function queryExpiredNotFinishPlanList(params) {
  return XHR({
    url: prefix + '/common/plan/web/queryExpiredNotFinishPlanList',
    method: 'POST',
    body: params
  });
}

//当期计划列表
export async function queryCurPeriodPlanList(params) {
  return XHR({
    url: prefix + '/position/workBench/web/queryWorkPlan',
    method: 'POST',
    body: params
  });
}

//登录时间
export async function recordPunchTime() {
  return XHR({
    url: prefix + '/authcenter/account/web/recordPunchTime',
    method: 'get'
  });
}

//啊啊啊啊
export async function savePlan(params) {
  return XHR({
    url: prefix + '/common/plan/web/savePlan',
    method: 'POST',
    body: params
  });
}
// //新建职位(已加网关)
// export async function createPosition(params) {
//   return XHR({
//     url: prefix + '/position/web/save',
//     body: params,
//     method: 'POST'
//   });
// }

//个人工作台完成情况查询
export async function queryFinishInfo(params) {
  return XHR({
    url: prefix + '/position/workBench/web/queryFinishInfo',
    method: 'POST',
    body: params
  });
}

//日历工作计划
export async function workPlanCalendar(params) {
  return XHR({
    url: prefix + '/position/workBench/web/workPlanCalendar',
    method: 'POST',
    body: params
  });
}
//某个人汇报线所在的组
// export async function getSpecifiedAcccountGroup(params) {
//   return XHR({
//     url: prefix + '/authcenter/group/web/getSpecifiedAcccountGroup/' + params,
//     method: 'GET'
//   });
// }
//某个人汇报线所在的组
//向登录人汇报的所有人所在的组
// export async function getSpecifiedAcccountGroup() {
//   return XHR({
//     url: prefix + '/authcenter/group/web/getGroupsReporterToAccount',
//     method: 'GET'
//   });
// }
//根据团队找账号列表
// export async function getAccountsByGroupId(params) {
//   return XHR({
//     url: prefix + '/authcenter/account/web/getAccountsByGroupId/' + params,
//     method: 'GET'
//   });
// }
//根据团队查找向登录人汇报的账号列表
export async function getAccountsByGroupId(params) {
  return XHR({
    url: prefix + '/authcenter/account/web/getAccountsByLoginAccountId/' + params,
    method: 'GET'
  });
}
//团队工作台完成情况查询
export async function queryTeamFinishInfo(params) {
  return XHR({
    url: prefix + '/position/workBench/web/queryTeamFinishInfo',
    method: 'POST',
    body: params
  });
}
//修改计划状态
export async function updateStatus(params) {
  return XHR({
    url: prefix + '/common/plan/web/updateStatus',
    method: 'POST',
    body: params
  });
}
//查询当期完成率
export async function finishRate(params) {
  return XHR({
    url: prefix + '/common/plan/web/finishRate',
    method: 'POST',
    body: params
  });
}
//是否存在某个人向登录人汇报
export async function isExistReporter() {
  return XHR({
    url: prefix + '/authcenter/account/web/isExistReporter',
    method: 'GET'
  });
}

//运营台start
//团队树形结构
export async function groupList() {
  return XHR({
    url: prefix + '/authcenter/group/web/treeList',
    method: 'GET',
  });
}

//获取流程节点
export async function getFlowNodes() {
  return XHR({
    url: prefix + '/position/positionFlow/web/getFlowNodes',
    method: 'GET',
  });
}

//保存职位流程
export async function savePositionFlow(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/save',
    method: 'POST',
    body: params
  });
}

//获取流程节点中的收费节点
export async function getChargeNodes(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/getChargeNodes',
    method: 'GET',
    body: params
  });
}
//查询职位流程模板
export async function findList(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/findList',
    method: 'POST',
    body: params
  });
}

//模板状态变更
export async function positionFlowChangeStatus(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/changeStatus' + params,
    method: 'GET',
  });
}

//查询职位流程
export async function positionFlowQuery(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/query/' + params,
    method: 'GET'
  });
}
//获取最近的收费流程节点id
export async function getFlowLatestChargeId(params) {
  return XHR({
    url: prefix + '/position/positionFlow/web/getFlowLatestChargeId?startId=' + params,
    method: 'GET'
  });
}

//工作台开始
//删除计划
export async function removePlan(params) {
  return XHR({
    url: prefix + '/common/plan/web/removePlan/' + params,
    method: 'POST'
  });
}

//查询计划
export async function findById(params) {
  return XHR({
    url: prefix + '/common/plan/web/findById/' + params,
    method: 'GET'
  });
}

//修改计划
export async function updatePlan(params) {
  return XHR({
    url: prefix + '/common/plan/web/updatePlan',
    method: 'POST',
    body: params
  });
}

//工作台完成情况活动统计列表查询(沟通联系，推荐初审，初审反馈，客户反馈，确认签署offer
export async function queryActivityByCondition(params) {
  return XHR({
    url: prefix + '/position/workBench/web/queryActivityByCondition',
    method: 'POST',
    body: params
  });
}
//工作台完成情况新建职位列表查询
export async function queryNewPositionByCondition(params) {
  return XHR({
    url: prefix + '/position/workBench/web/queryNewPositionByCondition',
    method: 'POST',
    body: params
  });
}
//工作台完成情况面试安排第一轮列表查询
export async function queryInterviewFirstByCondition(params) {
  return XHR({
    url: prefix + '/position/workBench/web/queryInterviewFirstByCondition',
    method: 'POST',
    body: params
  });
}

//工作台完成情况沟通联系列表查询
export async function queryCommunRelationByCondition(params) {
  return XHR({
    url: prefix + '/position/workBench/web/queryCommunRelationByCondition',
    method: 'POST',
    body: params
  });
}

//工作台完成情况新建简历或有效候选人列表查询
export async function queryCandidateOperateLogsByCondition(params) {
  return XHR({
    url: prefix + '/position/workBench/web/queryCandidateOperateLogsByCondition',
    method: 'POST',
    body: params
  });
}
//根据条件查询向登录人汇报的所有人汇报线信息（工作台汇报线）
export async function getWorkBeachAccountReportersPullDown(params) {
  return XHR({
    url: prefix + '/authcenter/group/web/getWorkBeachAccountReportersPullDown',
    method: 'GET',
    body: params
  });
}

//保存客户合同接口
export async function customerContractApi(params) {
  return XHR({
    url: prefix + '/position/customer/web/saveCustomerContract',
    method: 'POST',
    body: params
  });
}

//查询客户合同详情
export async function findContractByIdApi(params) {
  return XHR({
    url: prefix + '/position/customer/web/findCustContractById',
    method: 'GET',
    body: params
  });
}

//查询客户合同列表
export async function findCustContractApi(params) {
  return XHR({
    url: prefix + '/position/customer/web/findCustContractPojoList',
    method: 'POST',
    body: params
  });
}

//common/operation/web/findDocument
export async function findDocument(params) {
  return XHR({
    url: prefix + '/common/operation/web/findDocument',
    method: 'GET',
    body: params
  });
}